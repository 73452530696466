import React from 'react'
import CustomerList from '../../components/customer/CustomerList'
const CustomerPage = () => {
  return (
    <div>
      <CustomerList/>      
    </div>
  )
}

export default CustomerPage
