import { jwtDecode } from "jwt-decode";
import claims, { role } from "./userClaims";

export const getToken = localStorage.getItem("token");
export const getTokenExpiration = localStorage.getItem("tokenExpiration");


export const getTokenRoles = () => {
    
    const decoded = getToken !=null? jwtDecode(getToken) : null;
    var currentRole = new Array(70);
    if (decoded !=null) {
        var roles = decoded[role]
        for (let i = 0; i < roles.length; i++) {
          if (roles[i] === claims[i]) {
            currentRole[i] = claims[i]
          }
        }
        return currentRole;

    }

};

export const getRoleAdmin = () => {
  const decoded = getToken !=null? jwtDecode(getToken) : null
  var getRole = "";
  
  if (decoded !=null) {
    var roles = decoded[role]
    const claimsAdmin = ["admin"];
     for (let i = 0; i < roles?.length; i++) {
       if (roles[i] === claimsAdmin[i]) {
         getRole = "admin"
       }
     }
     return getRole;
  }
}