
export const GET_ALL_SERVICE_ORDER_START = "GET_ALL_SERVICE_ORDER_START";
export const GET_ALL_SERVICE_ORDER_SUCCESS = "GET_ALL_SERVICE_ORDER_SUCCESS";
export const GET_ALL_SERVICE_ORDER_ERROR = "GET_ALL_SERVICE_ORDER_ERROR";



export const ADD_SERVICE_ORDER_START = "ADD_SERVICE_ORDER_START";
export const ADD_SERVICE_ORDER_SUCCESS = "ADD_SERVICE_ORDER_SUCCESS";
export const ADD_SERVICE_ORDER_ERROR = "ADD_SERVICE_ORDER_ERROR";


export const DELETE_SERVICE_ORDER_START = "DELETE_SERVICE_ORDER_START";
export const DELETE_SERVICE_ORDER_SUCCESS = "DELETE_SERVICE_ORDER_SUCCESS";
export const DELETE_SERVICE_ORDER_ERROR = "DELETE_SERVICE_ORDER_ERROR";