import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const LoadingComponent = () => {
  return (
    <div className="loadingBox">
    <div className="my-5">
    <Skeleton height={400} />
    </div>
  </div>
  )
}

export default LoadingComponent
