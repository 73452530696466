import React from "react";
import "../assets/scss/loginPage.scss";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { authLoginAction } from "../redux/actions/authAction";
import { loginValidation } from "../constants/yup/validation";


const LoginPage = (p) => {
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        email: "",
        password: "",
      },
      onSubmit: (values) => {
        const data = {
          email: values.username,
          password: values.password,
        };
        p.authLoginAction(data);
      },
      validationSchema: loginValidation,
    }
  );

  return (
    <div className="loginPage">
      <div className="container">
        <div className="row justify-content-center align-items-center m-auto">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="logoBox">
              <div className="d-flex align-items center justify-content-center">
                <img
                  style={{ width: "400px", margin: "auto" }}
                  className="img-fluid"
                  src="/images/logo.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="loginPhoto">
              <img className="img-fluid" src="images/login.jpg" alt="" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="loginForm">
              <form onSubmit={handleSubmit}>
                <label htmlFor="username">Elektron ünvan</label>
                <input
                  type="email"
                  name="username"
                  id="username"
                  placeholder="epoçt"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.username && touched.username && (
                  <span>{errors.username}</span>
                )}
                <label htmlFor="password">Giriş şifrəsi</label>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="password"
                  name="password"
                  id="password"
                  placeholder="şifrə"
                />
                {errors.password && touched.password && (
                  <span>{errors.password}</span>
                )}
                <button
                  className="loginBtn btn btn-outline-success"
                  type="submit"
                >
                  Daxil ol
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.authLogin,
  };
};
export default connect(mapStateToProps, { authLoginAction })(LoginPage);
