import {
  ADD_BEAUTY_EXPERT_TO_SERVICE_ERROR,
  ADD_BEAUTY_EXPERT_TO_SERVICE_START,
  ADD_BEAUTY_EXPERT_TO_SERVICE_SUCCESS,
  GET_ALL_BEAUTY_EXPERT_TO_SERVICE_ERROR,
  GET_ALL_BEAUTY_EXPERT_TO_SERVICE_START,
  GET_ALL_BEAUTY_EXPERT_TO_SERVICE_SUCCESS,
} from "../actionTypes/beautyExpertToServiceActionTypes";

const ADD_INITIAL_STATE = {
  beautyExpertToService: {},
  isLoading: false,
  error: "ERROR THIS API",
};

export const beautyExpertToServiceAddReducer = (
  state = ADD_INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case ADD_BEAUTY_EXPERT_TO_SERVICE_START:
      return { ...state, error: "", isLoading: true };
    case ADD_BEAUTY_EXPERT_TO_SERVICE_SUCCESS:
      return { ...state, beautyExpertToService: payload, isLoading: false };
    case ADD_BEAUTY_EXPERT_TO_SERVICE_ERROR:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};

const GET_ALL_INITIAL_STATE = {
  beautyExpertToServices: [],
  filter: [],
  isLoading: false,
  error: "ERROR THIS API",
};

export const beautyExpertToServiceGetAllReducer = (
  state = GET_ALL_INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case GET_ALL_BEAUTY_EXPERT_TO_SERVICE_START:
      return { ...state, error: "", isLoading: true };
    case GET_ALL_BEAUTY_EXPERT_TO_SERVICE_SUCCESS:
      return { ...state, beautyExpertToServices: payload, isLoading: false };
    case GET_ALL_BEAUTY_EXPERT_TO_SERVICE_ERROR:
      return { ...state, error: payload, isLoading: false };
      default:
        return state;
  }
};
