import {
  ADD_OUTGOING_ERROR,
  ADD_OUTGOING_START,
  ADD_OUTGOING_SUCCESS,
  DELETE_OUTGOING_ERROR,
  DELETE_OUTGOING_START,
  DELETE_OUTGOING_SUCCESS,
  GET_ALL_OUTGOING_ERROR,
  GET_ALL_OUTGOING_START,
  GET_ALL_OUTGOING_SUCCESS,
  GET_OUTGOING_ERROR,
  GET_OUTGOING_START,
  GET_OUTGOING_SUCCESS,
  UPDATE_OUTGOING_ERROR,
  UPDATE_OUTGOING_START,
  UPDATE_OUTGOING_SUCCESS,
} from "../actionTypes/outGoingActionTypes";

const ADD_INITIAL_DATA = {
  outGoing: {},
  isLoading: false,
  error: "ERROR THIS API",
};

export const addOutGoingReducer = (
  state = ADD_INITIAL_DATA,
  { type, payload }
) => {
  switch (type) {
    case ADD_OUTGOING_START:
      return { ...state, isLoading: true, error: "" };
    case ADD_OUTGOING_SUCCESS:
      return { ...state, outGoing: payload, isLoading: false };
    case ADD_OUTGOING_ERROR:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};

const ALL_INITIAL_DATA = {
  outGoings: [],
  isLoading: false,
  error: "ERROR THIS API",
};

export const getAllOutGoingReducer = (
  state = ALL_INITIAL_DATA,
  { type, payload }
) => {
  switch (type) {
    case GET_ALL_OUTGOING_START:
      return { ...state, isLoading: true, error: "" };
    case GET_ALL_OUTGOING_SUCCESS:
      return { ...state, outGoings: payload, isLoading: false };
    case GET_ALL_OUTGOING_ERROR:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};


const DELETE_INITIAL_DATA = {
  outGoingId:0, isLoading:false, error:"ERROR THIS API",
};

export const deleteOutGoingReducer = (state= DELETE_INITIAL_DATA, {type, payload}) => {
  switch(type){
    case DELETE_OUTGOING_START : return {...state, isLoading:true, error:""}
    case DELETE_OUTGOING_SUCCESS : return {...state, outGoingId:payload, isLoading:false}
    case DELETE_OUTGOING_ERROR : return {...state, error:payload, isLoading:false}
    default : return state; 
  }
}

const GET_INITIAL_DATA = {
  outGoingId:0, isLoading:false, error:"ERROR THIS API",
};

export const getOutGoingReducer = (state= GET_INITIAL_DATA, {type, payload}) => {
  switch(type){
    case GET_OUTGOING_START : return {...state, isLoading:true, error:""}
    case GET_OUTGOING_SUCCESS : return {...state, outGoingId:payload, isLoading:false}
    case GET_OUTGOING_ERROR : return {...state, error:payload, isLoading:false}
    default : return state; 
  }
}

const UPDATE_INITIAL_DATA = {
 outGoing:{}, isLoading:false, error:"ERROR THIS API",
};

export const updateOutGoingReducer = (state= UPDATE_INITIAL_DATA, {type, payload}) => {
  switch(type){
    case UPDATE_OUTGOING_START : return {...state, isLoading:true, error:""}
    case UPDATE_OUTGOING_SUCCESS : return {...state, outGoing:payload, isLoading:false}
    case UPDATE_OUTGOING_ERROR : return {...state, error:payload, isLoading:false}
    default : return state; 
  }
}