import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import NotInterestedIcon from '@mui/icons-material/NotInterested';

const Forbidden = () => {
  return (
    <div className="forbiddenPage">
      <Header />
      <div className="container-fluid">
       <div className="headText">
       <div className="d-flex align-items-center justify-content-center">
       <h1 style={{color:"red"}} className="text-center my-3 mx-3">Yetkiniz Yoxdur </h1>
       <div className="notIco m-0 p-0">
       <NotInterestedIcon color="error" />
       </div>
       </div>
       </div>
        <div className="row align-items-center justify-content-center">
          <img style={{width:"100%", height:"500px", objectFit:"contain"}} src="images/403.jpg" alt="" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Forbidden;
