import React from "react";
import Header from "../../components/Header/Header";
import Form from "../../components/form/Form";
import { AddTask } from "@mui/icons-material";
import MyInput from "../../components/form/MyInput";
import ErrorText from "../../components/form/ErrorText";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { addCourseValidation } from "../../constants/yup/validation";
import { addCourseAction } from "../../redux/actions/courseAction";

const AddCourse = (s) => {
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        educationName: "",
        teacherName: "",
        studentName: "",
        coursePrice: 0,
        teacherPriceRate: 0,
        teacherGainPrice: 0,
        saloonGainPrice: 0,
        courseStartedDate: new Date(),
        courseCreatedDate: new Date()
      },
      onSubmit: (values) => {
        const data = {
            educationName: values.educationName,
            teacherName: values.teacherName,
            studentName: values.studentName,
            coursePrice: Number(values.coursePrice),
            teacherPriceRate: Number(values.teacherPriceRate),
            teacherGainPrice: ((Number(values.coursePrice) * Number(values.teacherPriceRate))/100),
            saloonGainPrice: (Number(values.coursePrice) - ((Number(values.coursePrice) * Number(values.teacherPriceRate))/100)),
            courseStartedDate: values.courseStartedDate,
            courseCreatedDate: new Date()

        };
        s.addCourseAction(data);
      },
      validationSchema: addCourseValidation,
    }
  );
  return (
    <div className="addCourse">
      <Header />
      <div className="addForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/addCourse.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Yeni Kurs əlavə et"}
                btnClass="btn btn-success"
                btnTitle="Əlavə et"
                btnIco={<AddTask />}
              >
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="educationName"
                  name="educationName"
                  titleName={"kurs"}
                  placeholder="Kurs adı"
                  inputType="text"
                />
                <ErrorText
                  error={errors.educationName}
                  touched={touched.educationName}
                  errorMessage={errors.educationName}
                  name={"educationName"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="teacherName"
                  name="teacherName"
                  titleName={"Müəllim"}
                  placeholder="Müəllim adı və soyadı"
                  inputType="text"
                />
                <ErrorText
                  error={errors.teacherName}
                  touched={touched.teacherName}
                  errorMessage={errors.teacherName}
                  name={"teacherName"}
                />
                 <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="studentName"
                  name="studentName"
                  titleName={"Tələbə"}
                  placeholder="Tələbə adı və soyadı"
                  inputType="text"
                />
                <ErrorText
                  error={errors.studentName}
                  touched={touched.studentName}
                  errorMessage={errors.studentName}
                  name={"studentName"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="coursePrice"
                  name="coursePrice"
                  titleName={"qiymət"}
                  placeholder="Kursun qiyməti"
                  inputType="text"
                />
                <ErrorText
                  error={errors.coursePrice}
                  touched={touched.coursePrice}
                  errorMessage={errors.coursePrice}
                  name={"coursePrice"}
                />
                 <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="teacherPriceRate"
                  name="teacherPriceRate"
                  titleName={"müəllim faizi"}
                  placeholder="Müəllimin faizi"
                  inputType="text"
                />
                <ErrorText
                  error={errors.teacherPriceRate}
                  touched={touched.teacherPriceRate}
                  errorMessage={errors.teacherPriceRate}
                  name={"teacherPriceRate"}
                />
                   <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="courseStartedDate"
                  name="courseStartedDate"
                  titleName={"Kurs başlama tarixi"}
                  inputType="date"
                />
                <ErrorText
                  error={errors.courseStartedDate}
                  touched={touched.courseStartedDate}
                  errorMessage={errors.courseStartedDate}
                  name={"courseStartedDate"}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addCourse: state.addCourse,
  };
};

export default connect(mapStateToProps, { addCourseAction })(
  AddCourse
);
