import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Form from "../../components/form/Form";
import {  EditNote } from "@mui/icons-material";
import MyInput from "../../components/form/MyInput";
import ErrorText from "../../components/form/ErrorText";
import { useFormik } from "formik";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router";

import { getItemRentRoomAction, updateRentRoomAction } from "../../redux/actions/rentRoomAction";

const UpdateRentRoom = (s) => {
  const rentRoom = useSelector((state) => state.getRentRoom);
  const { rentRoomId } = useParams();
  let getId = rentRoomId;
  const [id, setId] = useState(0);

  useEffect(() => {
    setId(getId);

    return s.getItemRentRoomAction(id);
  }, [id]);
  
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        id: id,
        roomNo: "",
        roomName: null,
        rentPrice: null,
        rentDate: null,
        isDelete: null,
      },
      onSubmit: (values) => {
        const data = {
          id: id,
          roomNo:
            values.roomNo == "" || values.roomNo == null
              ? rentRoom.rentRoomId.data?.roomNo
              : values.roomNo,
              roomName:
            values.roomName == "" || values.roomName == null
              ? rentRoom.rentRoomId.data?.roomName
              : values.roomName,
              rentPrice:
            values.rentPrice == "" || values.rentPrice == null
              ? rentRoom.rentRoomId.data?.rentPrice
              : Number(values.rentPrice),
              rentDate:
            values.rentDate == "" || values.rentDate == null
              ? rentRoom.rentRoomId.data?.rentDate
              : values.rentDate,
          isDelete:
            values.isDelete == "" || values.isDelete == null
              ? Boolean(rentRoom.rentRoomId.data?.isDelete)
              : values.isDelete,
        };
        console.log(data);

        s.updateRentRoomAction(data);
      },
    }
  );
  return (
    <div className="updateRentRoom">
      <Header />
      <div className="addForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/addCourse.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Icarədə dəyişiklik et"}
                btnClass="btn btn-success"
                btnTitle="Düzəliş et"
                btnIco={<EditNote />}
              >
                 <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="roomNo"
                  name="roomNo"
                  titleName={"otaq no"}
                  placeholder="otaq nömrəsi"
                  inputType="text"
                  defaultVal={rentRoom.rentRoomId.data?.roomNo}
                />
                <ErrorText
                  error={errors.roomNo}
                  touched={touched.roomNo}
                  errorMessage={errors.roomNo}
                  name={"roomNo"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="roomName"
                  name="roomName"
                  titleName={"otaq adı"}
                  placeholder="otaq adı"
                  inputType="text"
                  defaultVal={rentRoom.rentRoomId.data?.roomName}
                />
                <ErrorText
                  error={errors.roomName}
                  touched={touched.roomName}
                  errorMessage={errors.roomName}
                  name={"roomName"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="rentPrice"
                  name="rentPrice"
                  titleName={"qiymət"}
                  placeholder="icarənin qiyməti"
                  inputType="text"
                  defaultVal={rentRoom.rentRoomId.data?.rentPrice}
                />
                <ErrorText
                  error={errors.rentPrice}
                  touched={touched.rentPrice}
                  errorMessage={errors.rentPrice}
                  name={"rentPrice"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="rentDate"
                  name="rentDate"
                  titleName={"icarə müddəti"}
                  placeholder={"icarə müddəti"}
                  inputType="text"
                  defaultVal={rentRoom.rentRoomId.data?.rentDate}
                />
                <ErrorText
                  error={errors.rentDate}
                  touched={touched.rentDate}
                  errorMessage={errors.rentDate}
                  name={"rentDate"}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    updateRentRoom: state.updateRentRoom,
    getRentRoom: state.getRentRoom,
  };
};

export default connect(mapStateToProps, {
  updateRentRoomAction,
  getItemRentRoomAction,
})(UpdateRentRoom);
