import {
  ADD_BEAUTY_SERVICE_ERROR,
  ADD_BEAUTY_SERVICE_START,
  ADD_BEAUTY_SERVICE_SUCCESS,
} from "../actionTypes/beautyServiceActionTypes";
import {
  DELETE_SERVICE_ORDER_ERROR,
  DELETE_SERVICE_ORDER_START,
  DELETE_SERVICE_ORDER_SUCCESS,
  GET_ALL_SERVICE_ORDER_ERROR,
  GET_ALL_SERVICE_ORDER_START,
  GET_ALL_SERVICE_ORDER_SUCCESS,
} from "../actionTypes/serviceOrderActionTypes";

const ADD_INITIAL_DATA = {
  serviceOrder: {},
  isLoading: false,
  error: "ERROR THIS API",
};

export const addServiceOrderReducer = (
  state = ADD_INITIAL_DATA,
  { type, payload }
) => {
  switch (type) {
    case ADD_BEAUTY_SERVICE_START:
      return { ...state, error: "", isLoading: true };
    case ADD_BEAUTY_SERVICE_SUCCESS:
      return { ...state, serviceOrder: payload, isLoading: false };
    case ADD_BEAUTY_SERVICE_ERROR:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};

const GET_ALL_INITIAL_STATE_DATA = {
  serviceOrders: [],
  filter: [],
  isLoading: false,
  error: "ERROR THIS API",
};

export const getAllServiceOrdersReducer = (
  state = GET_ALL_INITIAL_STATE_DATA,
  { type, payload }
) => {
  switch (type) {
    case GET_ALL_SERVICE_ORDER_START:
      return { ...state, isLoading: true, error: "" };
    case GET_ALL_SERVICE_ORDER_SUCCESS:
      return {
        ...state,
        serviceOrders: payload,
        filter: payload,
        isLoading: false,
      };
    case GET_ALL_SERVICE_ORDER_ERROR:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};

const DELETE_INITIAL_DATA = {
  serviceOrderId:0, isLoading:false, error:"ERROR THIS API",
};

export const deleteServiceOrderReducer = (state= DELETE_INITIAL_DATA, {type, payload}) => {
  switch(type){
    case DELETE_SERVICE_ORDER_START : return {...state, isLoading:true, error:""}
    case DELETE_SERVICE_ORDER_SUCCESS : return {...state, serviceOrderId:payload, isLoading:false}
    case DELETE_SERVICE_ORDER_ERROR : return {...state, error:payload, isLoading:false}
    default : return state; 
  }
}
