import {
  ADD_BEAUTY_SERVICE_ERROR,
  ADD_BEAUTY_SERVICE_START,
  ADD_BEAUTY_SERVICE_SUCCESS,
  DELETE_BEAUTY_SERVICE_ERROR,
  DELETE_BEAUTY_SERVICE_START,
  DELETE_BEAUTY_SERVICE_SUCCESS,
  GET_BEAUTY_SERVICE_ERROR,
  GET_BEAUTY_SERVICE_START,
  GET_BEAUTY_SERVICE_SUCCESS,
} from "../actionTypes/beautyServiceActionTypes";

const INITIAL_STATE = {
  beautyServices: [],
  filter: [],
  isLoading: false,
  error: "ERROR THIS API",
};

export const getAllBeautyServiceReducer = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case GET_BEAUTY_SERVICE_START:
      return { ...state, error: "", isLoading: true };
    case GET_BEAUTY_SERVICE_SUCCESS:
      return { ...state, beautyServices: payload, isLoading: false };
    case GET_BEAUTY_SERVICE_ERROR:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};

const ADD_INITIAL_STATE = {
  beautyService: {},
  isLoading: false,
  error: "ERROR THIS API",
};

export const addBeautyServiceReducer = (
  state = ADD_INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case ADD_BEAUTY_SERVICE_START:
      return { ...state, error: "", isLoading: true };
    case ADD_BEAUTY_SERVICE_SUCCESS:
      return { ...state, beautyService: payload, isLoading: false };
    case ADD_BEAUTY_SERVICE_ERROR:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};


const DELETE_INITIAL_DATA = {
  beautyServiceId:0, isLoading:false, error:"ERROR THIS API",
};

export const deleteBeautyServiceReducer = (state= DELETE_INITIAL_DATA, {type, payload}) => {
  switch(type){
    case DELETE_BEAUTY_SERVICE_START : return {...state, isLoading:true, error:""}
    case DELETE_BEAUTY_SERVICE_SUCCESS : return {...state, beautyServiceId:payload, isLoading:false}
    case DELETE_BEAUTY_SERVICE_ERROR : return {...state, error:payload, isLoading:false}
    default : return state; 
  }
}
