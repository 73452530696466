import axios from "axios";
import { baseUrl } from "../../api/baseApi";
import { config } from "../../api/configApi";
import {
  ADD_RENT_ROOM_ERROR,
  ADD_RENT_ROOM_START,
  ADD_RENT_ROOM_SUCCESS,
  DELETE_RENT_ROOM_ERROR,
  DELETE_RENT_ROOM_START,
  DELETE_RENT_ROOM_SUCCESS,
  GET_ALL_RENT_ROOM_ERROR,
  GET_ALL_RENT_ROOM_START,
  GET_ALL_RENT_ROOM_SUCCESS,
  GET_RENT_ROOM_ERROR,
  GET_RENT_ROOM_START,
  GET_RENT_ROOM_SUCCESS,
  UPDATE_RENT_ROOM_ERROR,
  UPDATE_RENT_ROOM_START,
  UPDATE_RENT_ROOM_SUCCESS,
} from "../actionTypes/rentRoomActionTypes";
import { getToken } from "../../constants/role/getToken";

export const addRentRoomAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_RENT_ROOM_START,
  });

  axios
    .post(`${baseUrl}/RentRoom/addRentRoom`, data, config)
    .then((response) => {
      dispatch({
        type: ADD_RENT_ROOM_SUCCESS,
        payload: response.data,
      });
      window.location.replace("/allRentRoom");
    })
    .catch((error) => {
      dispatch({
        type: ADD_RENT_ROOM_ERROR,
        payload: error,
      });
    });
};

export const getAllRentRoomAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_RENT_ROOM_START,
  });
  const configGetAll = {
    headers: {
      Authorization: getToken,
    },
    params: {
      monthFilter: data.monthFilter,
    },
  };
  axios
    .get(`${baseUrl}/RentRoom/getAllRentRoom`, configGetAll)
    .then((response) => {
      dispatch({
        type: GET_ALL_RENT_ROOM_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_RENT_ROOM_ERROR,
        payload: error,
      });
    });
};


export const deleteRentRoomAction = (data) => (dispatch) => {
  const configDelete = {
    params: {
      rentRoomId: data.rentRoomId
    },
    headers: {
      Authorization: getToken,
    }
   
  };

  dispatch({
    type: DELETE_RENT_ROOM_START,
  });


  axios.put(`${baseUrl}/RentRoom/DeleteRentRoom`,null, configDelete).then((response)=>{
    dispatch({
      type: DELETE_RENT_ROOM_SUCCESS,
      payload: response.data,
    });

  }).catch((error)=>{
    dispatch({
      type: DELETE_RENT_ROOM_ERROR,
      payload:error
    })
  });
}


export const getItemRentRoomAction = (rentRoomId) => (dispatch) => {
 
  dispatch({
    type: GET_RENT_ROOM_START,
  });

  axios
    .get(`${baseUrl}/RentRoom/GetRentRoom/${rentRoomId}`, null, config)
    .then((response) => {
      dispatch({
        type: GET_RENT_ROOM_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_RENT_ROOM_ERROR,
        payload: error,
      });
    });
};


export const updateRentRoomAction = (data) => (dispatch) => {

  dispatch({
    type: UPDATE_RENT_ROOM_START,
  });

  axios
    .post(`${baseUrl}/RentRoom/UpdateRentRoom`, data, config)
    .then((response) => {
      dispatch({
        type: UPDATE_RENT_ROOM_SUCCESS,
        payload: response.data,
      });
      window.location.replace("/allRentRoom");
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_RENT_ROOM_ERROR,
        payload: error,
      });
    });
};
