import { ADD_COURSE_ERROR, ADD_COURSE_START, ADD_COURSE_SUCCESS, DELETE_COURSE_ERROR, DELETE_COURSE_START, DELETE_COURSE_SUCCESS, GET_ALL_COURSE_ERROR, GET_ALL_COURSE_START, GET_ALL_COURSE_SUCCESS, GET_COURSE_ERROR, GET_COURSE_START, GET_COURSE_SUCCESS, UPDATE_COURSE_ERROR, UPDATE_COURSE_START, UPDATE_COURSE_SUCCESS } from "../actionTypes/courseActionsType";

const ADD_INITIAL_DATA = {
    course: {},
    isLoading: false,
    error: "ERROR THIS API",
  };
  
  export const addCourseReducer = (
    state = ADD_INITIAL_DATA,
    { type, payload }
  ) => {
    switch (type) {
      case ADD_COURSE_START:
        return { ...state, isLoading: true, error: "" };
      case ADD_COURSE_SUCCESS:
        return { ...state, course: payload, isLoading: false };
      case ADD_COURSE_ERROR:
        return { ...state, error: payload, isLoading: false };
      default:
        return state;
    }
  };
  
  const ALL_INITIAL_DATA = {
    courses: [],
    isLoading: false,
    error: "ERROR THIS API",
  };
  
  export const getAllCourseReducer = (
    state = ALL_INITIAL_DATA,
    { type, payload }
  ) => {
    switch (type) {
      case GET_ALL_COURSE_START:
        return { ...state, isLoading: true, error: "" };
      case GET_ALL_COURSE_SUCCESS:
        return { ...state, courses: payload, isLoading: false };
      case GET_ALL_COURSE_ERROR:
        return { ...state, error: payload, isLoading: false };
      default:
        return state;
    }
  };
  
  
  const DELETE_INITIAL_DATA = {
    courseId:0, isLoading:false, error:"ERROR THIS API",
  };
  
  export const deleteCourseReducer = (state= DELETE_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case DELETE_COURSE_START : return {...state, isLoading:true, error:""}
      case DELETE_COURSE_SUCCESS : return {...state, courseId:payload, isLoading:false}
      case DELETE_COURSE_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }

  const GET_INITIAL_DATA = {
    courseId:0, isLoading:false, error:"ERROR THIS API",
  };
  
  export const getCourseReducer = (state= GET_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case GET_COURSE_START : return {...state, isLoading:true, error:""}
      case GET_COURSE_SUCCESS : return {...state, courseId:payload, isLoading:false}
      case GET_COURSE_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }
  
  const UPDATE_INITIAL_DATA = {
   course:{}, isLoading:false, error:"ERROR THIS API",
  };
  
  export const updateCourseReducer = (state= UPDATE_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case UPDATE_COURSE_START : return {...state, isLoading:true, error:""}
      case UPDATE_COURSE_SUCCESS : return {...state, course:payload, isLoading:false}
      case UPDATE_COURSE_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }