import Swal from "sweetalert2";

export const deleteFunc = (props) => {
    const myDel  = () => {
        return  props.deleteActionName
    }
        
   
    Swal.fire({
      title: "Silmək istədiyinizdən əminsiniz?",
      showDenyButton: true,
      confirmButtonText: "Sil",
      icon: "question",
      denyButtonText: `Geri`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        myDel()
        Swal.fire("Silindi!", "", "success").then(()=>{
            window.location.replace(props.locationReplaceName);
        });
       
      } else if (result.isDenied) {
        Swal.fire("Silinmədi", "", "info");
      }
    });
  };