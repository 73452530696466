import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getCustomersAction } from "../../redux/actions/customerAction";
import "./customer.scss";
import SubHeader from "../Header/SubHeader";
import DataTableComponent from "../dataTable/DataTableComponent";
import LoadingComponent from "../loadingBox/LoadingComponent";
import EmptyTableComponent from "../dataTable/EmptyTableComponent";
import ModifyButtonsComponent from "../dataTable/ModifyButtonsComponent";

const CustomerList = (props) => {
  const dataTableHead = [
    "No",
    "Ad",
    "Soyad",
    "Telefon",
    "Doğum tarixi",
    "Epoçt",
    "Ünvan",
    "",
  ];

  useEffect(() => {
    return props.getCustomersAction();
  }, []);

  return (
    <div className="customerList">
      <SubHeader
        backLink="/"
        title="Müştəri siyahısı"
        btnTitle="müştəri əlavə et"
        btnLink="/addCustomer"
      />

      <div className="container">
        <div className="customersTable">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                {props.customers.isLoading ? (
                  <LoadingComponent />
                ) : props.customers.customers?.data == null ? (
                  <EmptyTableComponent />
                ) : (
                  <DataTableComponent tableHeadData={dataTableHead}>
                    {props.customers?.customers?.data?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.firstName}</td>
                        <td>{item.lastName}</td>
                        <td>{item.phoneNumber}</td>
                        <td>{item.birthday.substring(0, 10)}</td>
                        <td>{item.email}</td>
                        <td>{item.address}</td>
                        <ModifyButtonsComponent />
                      </tr>
                    ))}
                  </DataTableComponent>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    customers: state.customers,
  };
};
export default connect(mapStateToProps, { getCustomersAction })(CustomerList);
