import React, { useEffect } from "react";
import DataTableComponent from "../../components/dataTable/DataTableComponent";
import SubHeader from "../../components/Header/SubHeader";
import LoadingComponent from "../../components/loadingBox/LoadingComponent";
import EmptyTableComponent from "../../components/dataTable/EmptyTableComponent";
import ModifyButtonsComponent from "../../components/dataTable/ModifyButtonsComponent";
import { connect } from "react-redux";
import { deleteOutGoingAction, getAllOutGoingAction } from "../../redux/actions/outGoingAction";
import { deleteFunc } from "../../constants/delete/DeleteForm";
import { useFormik } from "formik";

const AllOutGoing = (props) => {
  useEffect(() => {
    return props.getAllOutGoingAction({monthFilter:""});
  }, []);
  const dataTableHead = [
    "No",
    "Xərc adı",
    "Ətraflı",
    "Xərc qiyməti",
    "Xərclənmə tarixi",
    "Xərcin yaradılma tarixi",
    "Seç",
    "",
  ];


  const dataCounts = props.getAllOutGoings.outGoings?.data;
  const outgoingsPriceList = [];
  var totalPrice = 0;
  dataCounts?.map((item) => {
    return outgoingsPriceList.push(item.outGoingMoney);
  });
  outgoingsPriceList.map((item) => {
    return (totalPrice += item);
  });
  const dataTableFoot = {
    title: "Ümumi xərclər",
    content: totalPrice.toFixed(2) + " Azn",
  };
  const getIdFunc = (id) => {
    const myData = {
      outGoingId: id
     }
    deleteFunc({ deleteActionName:(props.deleteOutGoingAction(myData)),locationReplaceName:"/allOutGoings"});
  };
  const { handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      monthFilter: "",
    },

    onSubmit: (values) => {
      const data = {
        monthFilter: values.monthFilter,
      };
      props.getAllOutGoingAction(data);
    },
  });
  return (
    <div className="allOutGoing">
      <SubHeader
        backLink="/"
        title="Xərclər siyahısı"
        btnTitle="xərc əlavə et"
        btnLink="/addOutGoing"
      />
      <div className="container-fluid">
      <form
            style={{ marginBottom: "30px", marginTop: "20px" }}
            onSubmit={handleSubmit}
          >
            <div className="container">
              <div className="row align-items-center">
              
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                  <input
                    style={{
                      marginTop: "12px",
                      padding: "10px 25px",
                      borderRadius: "8px",
                      outline: "none",
                      border: "0.8px solid #ccc",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="monthFilter"
                    name="monthFilter"
                    type="month"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                  <button
                    className="btn btn-secondary"
                    style={{ marginTop: "15px", padding: "10px 20px" }}
                    type="submit"
                  >
                    Filter et
                  </button>
                </div>
              </div>
            </div>
          </form>
        <div className="row">
          {props.getAllOutGoings.isLoading ? (
            <LoadingComponent />
          ) : props.getAllOutGoings.outGoings?.data == null ? (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center my-5">
              <EmptyTableComponent />
            </div>
          ) : (
            <DataTableComponent
              dataTableFoot={dataTableFoot}
              tableHeadData={dataTableHead}
            >
              {props.getAllOutGoings.outGoings?.data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.outGoingName}</td>
                  <td>{item.description}</td>
                  <td>{item.outGoingMoney.toFixed(2)} Azn</td>
                  <td>{item.outGoingDate.substring(0, 10)}</td>
                  <td>{item.outGoingCreatedDate.substring(0, 10)}</td>
               
                  <ModifyButtonsComponent
                          delFunc={() => getIdFunc(item.id)}
                          linkUrl={`/UpdateOutGoing/${item.id}`}
                        />
                </tr>
              ))}
            </DataTableComponent>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getAllOutGoings: state.getAllOutGoings,
    deleteOutGoing: state.deleteOutGoing
  };
};

export default connect(mapStateToProps, { getAllOutGoingAction, deleteOutGoingAction })(AllOutGoing);
