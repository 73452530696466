import React from "react";
import "./dataTable.scss";
import PropTypes from "prop-types";

const DataTableComponent = (props) => {
  return (
    <div className="dataTableComponent">
      <table width={"100%"}>
        <thead>
          <tr>
            {props.tableHeadData?.map((item, index) => (
              <th key={index} scope="row">
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{props.children}</tbody>
        {
          props.dataTableFoot  !=null ?  <tfoot>
          <tr>
            <th>{props.dataTableFoot.title}</th>
            <td>{props.dataTableFoot.content}</td>
          </tr>
        </tfoot> : null
        }
        {
          props.dataTableFoot2  !=null ?  <tfoot>
          <tr>
            <th>{props.dataTableFoot2.title}</th>
            <td>{props.dataTableFoot2.content}</td>
          </tr>
        </tfoot> : null
        }
       
      </table>
    </div>
  );
};
DataTableComponent.propTypes = {
  tableHeadData: PropTypes.array.isRequired,
};
export default DataTableComponent;
