import React from "react";
import "./form.scss";

const Form = (props) => {
  return (
    <div className="formComponent">
      <div className="headText text-center">
        <h1>{props.titleForm}</h1>
      </div>
      <form onSubmit={props.submitFunc} className="myForm">
        {props.children}

        <button
          style={{
            width: "100%",
            marginTop: "30px",
            fontWeight: "500",
            fontSize: "20px",
          }}
          className={props.btnClass}
          type="submit"
        >
          {props.btnTitle} {props.btnIco}
        </button>
      </form>
    </div>
  );
};

export default Form;
