import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import ErrorText from "../../components/form/ErrorText";
import MyInput from "../../components/form/MyInput";
import { AddTask } from "@mui/icons-material";
import Form from "../../components/form/Form";
import { useFormik } from "formik";
import { addServiceOrderDetailAction } from "../../redux/actions/ServiceOrderDetailAction";
import { connect } from "react-redux";
import { getBeautyServiceAllAction } from "../../redux/actions/beautyServiceAction";
import { addServiceOrderDetailValidation } from "../../constants/yup/validation";
import { getAllPaymentMethodAction } from "../../redux/actions/PaymentMethodAction";

const ServiceOrderDetailAdd = (props) => {
  useEffect(()=>{
    return props.getAllPaymentMethodAction()
  },[])
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        serviceOrderId: 0,
        beautyServiceId: 0,
        quantity: 0,
        totalPrice: 0,
        disCountRate:0,
        disCountTotalPrice:0,
        detail: "",
        paymentMethodId:0,
        extraPrice:0,
      },
      onSubmit: (values) => {
        var serviceIndex = 0;
         props.getBeautyServices.beautyServices.data.map(
          (item, index) => {
            if (item.id == values.beautyServiceId) {
                serviceIndex = index;
            }
          }
        );
        const data = {
          serviceOrderId: values.serviceOrderId,
          beautyServiceId: values.beautyServiceId,
          quantity: values.quantity,
          disCountRate: values.disCountRate,
          extraPrice: Number(values.extraPrice),
          totalPrice:( (values.quantity * (props.getBeautyServices.beautyServices.data[serviceIndex].servicePrice))+ Number(values.extraPrice)),
          disCountTotalPrice: values.disCountRate >0? ((values.quantity * (props.getBeautyServices.beautyServices.data[serviceIndex].servicePrice)) + Number(values.extraPrice)) - (((values.quantity * ((props.getBeautyServices.beautyServices.data[serviceIndex].servicePrice))) + Number(values.extraPrice)) * values.disCountRate /100) : (values.quantity * (props.getBeautyServices.beautyServices.data[serviceIndex].servicePrice))+ Number(values.extraPrice),
          detail: values.detail,
          paymentMethodId: values.paymentMethodId,
        };
        props.addServiceOrderDetailAction(data);
      },
      validationSchema: addServiceOrderDetailValidation,
    }
  );
  useEffect(() => {
    return props.getBeautyServiceAllAction();
  }, []);

  return (
    <div className="serviceOrderDetailAdd">
      <Header />
      <div className="addForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/addService.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Yeni sifarişi tamamla"}
                btnClass="btn btn-success"
                btnTitle="Əlavə et"
                btnIco={<AddTask />}
              >
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="serviceOrderId"
                  name="serviceOrderId"
                  titleName={"sifariş kodu"}
                  placeholder="sifariş kodu"
                  inputType="number"
                />
                <ErrorText
                  error={errors.serviceOrderId}
                  touched={touched.serviceOrderId}
                  errorMessage={errors.serviceOrderId}
                  name={"serviceOrderId"}
                />
                  <label htmlFor="beautyServiceId">Ödəniş üsulu</label>
                <select
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="paymentMethodId"
                  id="paymentMethodId"
                  className="form-control"
                >
                  <option hidden defaultChecked defaultValue="choose">
                    Ödəniş üsulu
                  </option>
                  {props.getPaymentMethods.paymentMethods?.data?.map(
                    (item, index) => (
                      <option
                        id="paymentMethodId"
                        name="paymentMethodId"
                        key={index}
                        value={item.id}
                      >
                        {item.paymentMethodName}
                      </option>
                    )
                  )}
                </select>
                <label htmlFor="beautyServiceId">Xidmətlər</label>
                <select
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="beautyServiceId"
                  id="beautyServiceId"
                  className="form-control"
                >
                  <option hidden defaultChecked defaultValue="choose">
                    Xidmətlər
                  </option>
                  {props.getBeautyServices.beautyServices?.data?.map(
                    (item, index) => (
                      <option
                        id="beautyServiceId"
                        name="beautyServiceId"
                        key={index}
                        value={item.id}
                      >
                        {item.serviceName + "-" + item.serviceDetail + "-" + item.servicePrice + " AZN"}
                      </option>
                    )
                  )}
                </select>
                <ErrorText
                  error={errors.beautyServiceId}
                  touched={touched.beautyServiceId}
                  errorMessage={errors.beautyServiceId}
                  name={"beautyServiceId"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="quantity"
                  name="quantity"
                  titleName={"say"}
                  placeholder="xidmətin sayı"
                  inputType="number"
                />
                <ErrorText
                  error={errors.quantity}
                  touched={touched.quantity}
                  errorMessage={errors.quantity}
                  name={"quantity"}
                />
                     <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="extraPrice"
                  name="extraPrice"
                  titleName={"Əlavə qiymət"}
                  placeholder="Əlavə qiymət"
                  inputType="text"
                />
                <ErrorText
                  error={errors.extraPrice}
                  touched={touched.extraPrice}
                  errorMessage={errors.extraPrice}
                  name={"extraPrice"}
                />
                  <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="disCountRate"
                  name="disCountRate"
                  titleName={"endirim"}
                  placeholder="endirim faizi"
                  inputType="number"
                />
                <ErrorText
                  error={errors.disCountRate}
                  touched={touched.disCountRate}
                  errorMessage={errors.disCountRate}
                  name={"disCountRate"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="detail"
                  name="detail"
                  titleName={"ətraflı"}
                  placeholder="xidmət haqqında"
                  inputType="text"
                />
                <ErrorText
                  error={errors.detail}
                  touched={touched.detail}
                  errorMessage={errors.detail}
                  name={"detail"}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addServiceOrderDetail: state.addServiceOrderDetailAction,
    getBeautyServices: state.getBeautyServices,
    getPaymentMethods: state.paymentMethods
  };
};

export default connect(mapStateToProps, {
  addServiceOrderDetailAction,
  getBeautyServiceAllAction,
  getAllPaymentMethodAction
})(ServiceOrderDetailAdd);
