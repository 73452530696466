import React from 'react'
import './footer.scss'

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row align-items-center justify-content-space-between m-auto">
            <div className="col-6 col-sm-6 col-md-6 col-lg-10">
                <div className="developed">
                    <p>Developed by musalimurat</p>
                </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-2">
                <div className="developed">
                    <p>Müəllif hüquqları qorunur.</p>
                </div>
            </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
