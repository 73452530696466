import React from "react";
import Form from "../../components/form/Form";
import Header from "../../components/Header/Header";
import MyInput from "../../components/form/MyInput";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { customerAddAction } from "../../redux/actions/customerAction";
import ErrorText from "../../components/form/ErrorText";
import { addCustomerValidation } from "../../constants/yup/validation";
import { AddTask } from "@mui/icons-material";
const AddCustomerPage = (c) => {
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        address: "",
        birthday: "",
        registerDate: new Date(),
      },
      onSubmit: (values) => {
        const data = {
          FirstName: values.firstName,
          LastName: values.lastName,
          PhoneNumber: values.phoneNumber !==''? values.phoneNumber : "yoxdur",
          Address: values.address !==''? values.address : "yoxdur",
          Email: values.email !==''? values.email : "yoxdur",
          Birthday: values.birthday,
          RegisterDate: values.registerDate,
        };
        c.customerAddAction(data);
      },
      validationSchema: addCustomerValidation,
    }
  );
  var alreadyUser = localStorage.getItem("alreadyUser");
  return (
    <div className="customerAddPage">
      <Header />
      <div className="addForm my-3">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/customer.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Yeni müştəri əlavə et"}
                btnClass="btn btn-success"
                btnTitle="Əlavə et"
                btnIco={<AddTask />}
              >
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="firstName"
                  name="firstName"
                  titleName={"ad"}
                  placeholder="müştərinin adı"
                  inputType="text"
                />
                <ErrorText
                  error={errors.firstName}
                  touched={touched.firstName}
                  errorMessage={errors.firstName}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="lastName"
                  name="lastName"
                  titleName={"soyad"}
                  placeholder="müştərinin soyadı"
                  inputType="text"
                />
                <ErrorText
                  error={errors.lastName}
                  touched={touched.lastName}
                  errorMessage={errors.lastName}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="phoneNumber"
                  name="phoneNumber"
                  titleName={"telefon"}
                  placeholder="müştərinin telefonu"
                  inputType="text"
                />
                <ErrorText
                  error={errors.phoneNumber}
                  touched={touched.phoneNumber}
                  errorMessage={errors.phoneNumber}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="email"
                  name="email"
                  titleName={"epoçt"}
                  placeholder="müştərinin epoçtu"
                  inputType="email"
                />
                <ErrorText
                  error={errors.email}
                  touched={touched.email}
                  errorMessage={errors.email}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="address"
                  name="address"
                  titleName={"yaşayış ünvanı"}
                  placeholder="müştərinin yaşayış ünvanı"
                  inputType="text"
                />
                <ErrorText
                  error={errors.address}
                  touched={touched.address}
                  errorMessage={errors.address}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="birthday"
                  name="birthday"
                  titleName={"doğum tarixi"}
                  placeholder="müştərinin doğum tarixi"
                  inputType="date"
                />
                <ErrorText
                  error={errors.birthday}
                  touched={touched.birthday}
                  errorMessage={errors.birthday}
                />
                {alreadyUser != null ? (
                  <p style={{ color: "darkred" }}>{alreadyUser}</p>
                ) : null}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    addCustomer: state.customerAddAction,
  };
};
export default connect(mapStateToProps, { customerAddAction })(AddCustomerPage);
