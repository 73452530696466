import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import NotInterestedIcon from '@mui/icons-material/NotInterested';

const NotFound = () => {
  return (
    <div className="forbiddenPage">
      <Header />
      <div className="container-fluid">
    
        <div className="row align-items-center justify-content-center">
          <img style={{width:"100%", height:"600px", objectFit:"contain"}} src="images/404.jpg" alt="" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;