import React, { useEffect } from "react";
import DataTableComponent from "../../components/dataTable/DataTableComponent";
import SubHeader from "../../components/Header/SubHeader";
import LoadingComponent from "../../components/loadingBox/LoadingComponent";
import EmptyTableComponent from "../../components/dataTable/EmptyTableComponent";
import ModifyButtonsComponent from "../../components/dataTable/ModifyButtonsComponent";
import { connect } from "react-redux";
import {
  deleteDeptAction,
  getAllDeptAction,
} from "../../redux/actions/deptAction";
import { deleteFunc } from "../../constants/delete/DeleteForm";
import { useFormik } from "formik";

const DeptPage = (props) => {
  useEffect(() => {
    return props.getAllDeptAction({ monthFilter: "" });
  }, []);
  const dataTableHead = [
    "No",
    "Id-no",
    "Borc adı",
    "borc miqdarı",
    "Durum",
    "Borcun alınma tarixi",
    "Borcun yaradılma tarixi",
    "",
  ];

  const getIdFunc = (id) => {
    const myData = {
      deptId: id,
    };
    deleteFunc({
      deleteActionName: props.deleteDeptAction(myData),
      locationReplaceName: "/allDept",
    });
  };

  const dataCounts = props.allDept.allDept?.data;
  const deptPriceList = [];
  var totalPrice = 0;
  dataCounts?.map((item) => {
    return deptPriceList.push(item.deptPrice);
  });
  deptPriceList.map((item) => {
    return (totalPrice += item);
  });
  const dataTableFoot = {
    title: "Ümumi borc",
    content: totalPrice.toFixed(2) + " Azn",
  };
  const { handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      monthFilter: "",
    },

    onSubmit: (values) => {
      const data = {
        monthFilter: values.monthFilter,
      };
      props.getAllDeptAction(data);
    },
  });
  
  return (
    <div className="allDept">
      <SubHeader
        backLink="/"
        title="Borclar siyahısı"
        btnTitle="borc əlavə et"
        btnLink="/addDept"
      />
      <div className="container-fluid">
        <form
          style={{ marginBottom: "30px", marginTop: "20px" }}
          onSubmit={handleSubmit}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                <input
                  style={{
                    marginTop: "12px",
                    padding: "10px 25px",
                    borderRadius: "8px",
                    outline: "none",
                    border: "0.8px solid #ccc",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="monthFilter"
                  name="monthFilter"
                  type="month"
                />
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                <button
                  className="btn btn-secondary"
                  style={{ marginTop: "15px", padding: "10px 20px" }}
                  type="submit"
                >
                  Filter et
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="row">
          {props.allDept.isLoading ? (
            <LoadingComponent />
          ) : props.allDept.allDept?.data?.length==0 ? (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center my-5">
              <EmptyTableComponent />
            </div>
          ) : (
            <DataTableComponent
              dataTableFoot={dataTableFoot}
              tableHeadData={dataTableHead}
            >
              {props.allDept.allDept?.data?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.id}</td>
                  <td>{item.deptName}</td>
                  <td>{item.deptPrice.toFixed(2)} Azn</td>
                  <td>{item.isRefunded ? "Ödənilib" : "Ödənilməyib"}</td>
                  <td>{item.deptDate.substring(0, 10)}</td>
                  <td>{item.deptCreatedDate.substring(0, 10)}</td>

                  <ModifyButtonsComponent
                    delFunc={() => getIdFunc(item.id)}
                    linkUrl={`/UpdateDept/${item.id}`}
                  />
                </tr>
              ))}
            </DataTableComponent>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allDept: state.allDept,
    deleteDept: state.deleteDept,
  };
};

export default connect(mapStateToProps, { getAllDeptAction, deleteDeptAction })(
  DeptPage
);
