import React, { useEffect } from "react";
import SubHeader from "../../components/Header/SubHeader";
import LoadingComponent from "../../components/loadingBox/LoadingComponent";
import EmptyTableComponent from "../../components/dataTable/EmptyTableComponent";
import DataTableComponent from "../../components/dataTable/DataTableComponent";
import ModifyButtonsComponent from "../../components/dataTable/ModifyButtonsComponent";
import { connect } from "react-redux";
import { deleteOrderDetailServiceAction, getAllServiceOrderDetailAction } from "../../redux/actions/ServiceOrderDetailAction";
import { useFormik } from "formik";
import { getBeautyExpertAllAction } from "../../redux/actions/beautyExpertAction";
import { getRoleAdmin } from "../../constants/role/getToken";
import { MoneyOff } from "@mui/icons-material";
import { deleteFunc } from "../../constants/delete/DeleteForm";


const ServiceOrderDetails = (props) => {
  const dataTableHead = [
    "S-No",
    "Müştəri",
    "Sifariş",
    "Ətraflı",
    "Expert",
    "say",
    "Xidmət qiyməti",
    "Əlavə qiymət",
    "Ümumi qiymət endirimsiz",
    "ümumi endirim %",
    "Endirim sonu qiymət",
    "Expert iş %",
    "Expert qazancı",
    "Salon qazancı",
    "Ödəniş üsulu",
    "Sifariş tarixi",
    "Sifarişin yaranma tarixi",
    "",
  ];

  useEffect(() => {
    return (
      props.getAllServiceOrderDetailAction({
        take: 0,
        expertName: null,
        filterDate: "",
      }),
      props.getBeautyExpertAllAction()
    );
  }, []);
  const dataCount = props.getAllServiceOrderDetails.serviceOrderDetails?.data;
  const priceList = [];
  const expertPriceList = [];
  var totalGainPrice = 0;
  var totalExpertGainPrice = 0;
  const roleAdmin = getRoleAdmin();

  dataCount?.map((item) => {
    return (
      priceList.push(item.saloonPriceGain),
      expertPriceList.push(item.expertPriceGain)
    );
  });
  priceList.map((item) => (totalGainPrice += item));
  expertPriceList.map((item) => (totalExpertGainPrice += item));

  const dataTableFoot = {
    title: "Ümumi qazanc",
    content: totalGainPrice.toFixed(2) + " Azn",
  };
  const dataTableFoot2 = {
    title: "Expert qazanc",
    content: totalExpertGainPrice.toFixed(2) + " Azn",
  };
  const { handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      take: 0,
      expertName: "",
      filterDate: "",
      orderDetailId: 0,
    },

    onSubmit: (values) => {
      const data = {
        take: values.take,
        expertName: values.expertName,
        filterDate: values.filterDate,
      };
      props.getAllServiceOrderDetailAction(data);
    },
  });

  const getIdFunc = (id) => {
    const myData = {
      serviceOrderDetailId: id
     }
    deleteFunc({ deleteActionName:(props.deleteOrderDetailServiceAction(myData)),locationReplaceName:"/allServiceOrderDetails"});
  };
  return (
    <div className="serviceOrderDetails">
      <div className="serviceOrderHead">
        <SubHeader
          backLink="/"
          title="Sifariş siyahısı"
          btnTitle="sifariş əlavə et"
          btnLink="/allServiceOrder"
        />
      </div>
      <div className="serviceOrderBody">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
          <form
            style={{ marginBottom: "30px", marginTop: "20px" }}
            onSubmit={handleSubmit}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                  <select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="take"
                  >
                    <option hidden>Hamısı</option>
                    <option id="take" name="take" value={0}>
                      Hamısı
                    </option>
                    <option id="take" name="take" value={2}>
                      2
                    </option>
                    <option id="take" name="take" value={10}>
                      10
                    </option>
                    <option id="take" name="take" value={20}>
                      20
                    </option>
                    <option id="take" name="take" value={50}>
                      50
                    </option>
                    <option id="take" name="take" value={50}>
                      100
                    </option>
                    <option id="take" name="take" value={150}>
                      150
                    </option>
                    <option id="take" name="take" value={200}>
                      200
                    </option>
                  </select>
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                  <select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="expertName"
                  >
                    <option hidden>Expertlər</option>
                    <option id="expertName" name="expertName" value={""}>
                      Hamısı
                    </option>
                    {props.getBeautyExperts?.beautyExperts?.data?.map(
                      (item, index) => (
                        <option
                          key={index}
                          id="expertName"
                          name="expertName"
                          value={item.firstName + " " + item.lastName}
                        >
                          {item.firstName + " " + item.lastName}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                  <input
                    style={{
                      marginTop: "12px",
                      padding: "10px 25px",
                      borderRadius: "8px",
                      outline: "none",
                      border: "0.8px solid #ccc",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="filterDate"
                    name="filterDate"
                    type="month"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                  <button
                    className="btn btn-secondary"
                    style={{ marginTop: "15px", padding: "10px 20px" }}
                    type="submit"
                  >
                    Filter et
                  </button>
                </div>
              </div>
            </div>
          </form>
          {props.getAllServiceOrderDetails.isLoading ? (
            <LoadingComponent />
          ) : props.getAllServiceOrderDetails.serviceOrderDetails?.data ==
            null || props.getAllServiceOrderDetails.serviceOrderDetails?.data?.length ==
            0 ? (
            <EmptyTableComponent />
          ) : (
            <>
              <DataTableComponent
                tableHeadData={dataTableHead}
               
                dataTableFoot2={roleAdmin ==="admin"? dataTableFoot2 : null}
                dataTableFoot={roleAdmin ==="admin"? dataTableFoot : null}
              >
                {props.getAllServiceOrderDetails.serviceOrderDetails?.data?.map(
                  (item, index) => (
                    <tr key={index}>
                      <td>{item.serviceOrderDetailId}</td>
                      <td>{item.customerName}</td>
                      <td>{item.serviceOrderName}</td>
                      <td>{item.detail}</td>
                      <td>{item.beautyExpertName}</td>
                      <td>{item.quantity} ədəd</td>
                      <td>{item.servicePrice} Azn</td>
                      <td>{item.extraPrice} Azn</td>
                      <td>{item.totalPrice} Azn</td>
                      <td>{item.disCountRate} %</td>
                      <td>{item.disCountTotalPrice} Azn</td>
                      <td>{item.beautyExpertPriceRate} %</td>
                      {
                          roleAdmin === "admin" ? <>
                           <td>{item.expertPriceGain} Azn</td>
                           <td>{item.saloonPriceGain} Azn</td>
                          </> : <><td className="text-center"><MoneyOff color="error"/></td> <td className="text-center"><MoneyOff color="error"/></td></>
                      }
                        <td>{item.paymentMethodName}</td>
                      <td>{item.addOrderDate.substring(0, 10)}</td>
                      <td>{item.orderDate.substring(0, 10)}</td>
                        <ModifyButtonsComponent
                          delFunc={() => getIdFunc(item.serviceOrderDetailId)}
                        />
                    </tr>
                  )
                )}
              </DataTableComponent>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getAllServiceOrderDetails: state.getAllServiceOrderDetails,
    getBeautyExperts: state.getBeautyExperts,
    deleteOrderDetail: state.deleteOrderDetail
  };
};

export default connect(mapStateToProps, {
  getAllServiceOrderDetailAction,
  getBeautyExpertAllAction,
  deleteOrderDetailServiceAction
})(ServiceOrderDetails);
