import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { applyMiddleware, compose, createStore } from 'redux';
import { rootReducer } from './redux/reducers/rootReducer';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';



const root = ReactDOM.createRoot(document.getElementById('root'));
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleWare = applyMiddleware(thunk)
const store = createStore(rootReducer , composeEnhancers(middleWare));
root.render(
 
  <React.StrictMode>
    <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>
 
);

