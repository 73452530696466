import axios from "axios";

import {
  GET_CUSTOMERS_START,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_ERROR,
  POST_CUSTOMER_ADD_START,
  POST_CUSTOMER_ADD_SUCCESS,
  POST_CUSTOMER_ADD_ERROR,
} from "../actionTypes/customerActionTypes";
import { baseUrl } from "../../api/baseApi";
import { getToken } from "../../constants/role/getToken";

export const getCustomersAction = () => (dispatch) => {
  const config = {
    headers: {
      Authorization: getToken,
    },
  };
  dispatch({
    type: GET_CUSTOMERS_START,
  });
  axios
    .get(`${baseUrl}/Customer/getAllCustomer`, config)
    .then((response) => {
      dispatch({
        type: GET_CUSTOMERS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_CUSTOMERS_ERROR,
        payload: error,
      });
    });
};

export const customerAddAction = (data) => (dispatch) => {
  const config = {
    headers: {
      Authorization: getToken,
    },
  };
  dispatch({
    type: POST_CUSTOMER_ADD_START,
  });

  axios
    .post(`${baseUrl}/customer/addCustomer`, data, config)
    .then((response) => {
      dispatch({
        type: POST_CUSTOMER_ADD_SUCCESS,
        payload: response.data,
      });
      window.location.replace("/customer");

      var alreadyUser = localStorage.getItem("alreadyUser");
      if (alreadyUser != null) {
        localStorage.removeItem("alreadyUser");
      }
    })
    .catch((error) => {
      dispatch({
        type: POST_CUSTOMER_ADD_ERROR,
        payload: error,
      });
      localStorage.setItem("alreadyUser", error.response.data.message);
    });
};
