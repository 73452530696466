import { ADD_BEAUTY_EXPERT_ERROR, ADD_BEAUTY_EXPERT_START, ADD_BEAUTY_EXPERT_SUCCESS, DELETE_BEAUTY_EXPERT_ERROR, DELETE_BEAUTY_EXPERT_START, DELETE_BEAUTY_EXPERT_SUCCESS, GET_BEAUTY_EXPERT_ALL_ERROR, GET_BEAUTY_EXPERT_ALL_START, GET_BEAUTY_EXPERT_ALL_SUCCESS } from "../actionTypes/beautyExpertActionTypes"

const INITIAL_STATE = {
    beautyExperts:[], filter:[], isLoading:false, error:'ERROR THIS API'
}

export const getAllBeautyExpertReducer =(state=INITIAL_STATE, {type, payload}) =>{
    switch (type) {
        case GET_BEAUTY_EXPERT_ALL_START: return {...state, error:"", isLoading:true}
        case GET_BEAUTY_EXPERT_ALL_SUCCESS: return {...state, beautyExperts:payload, filter:payload, isLoading:false}
        case GET_BEAUTY_EXPERT_ALL_ERROR: return {...state, error:payload, isLoading:false} 
    
        default: return state;
           
    }
}

const ADD_INITIAL_STATE = {
    beautyExpert:{}, isLoading:false, error:"ERROR THIS API"
}

export const beautyExpertAddReducer = (state = ADD_INITIAL_STATE, {type, payload})=>{
    switch(type){
        case ADD_BEAUTY_EXPERT_START : return {...state, error:"", isLoading:true}
        case ADD_BEAUTY_EXPERT_SUCCESS : return {...state, beautyExpert:payload, isLoading:false}
        case ADD_BEAUTY_EXPERT_ERROR : return {...state, error:payload, isLoading:false}
        default : return state
    }
}


const DELETE_INITIAL_DATA = {
    beautyExpertId:0, isLoading:false, error:"ERROR THIS API",
  };
  
  export const deleteBeautyExpertReducer = (state= DELETE_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case DELETE_BEAUTY_EXPERT_START : return {...state, isLoading:true, error:""}
      case DELETE_BEAUTY_EXPERT_SUCCESS : return {...state, beautyExpertId:payload, isLoading:false}
      case DELETE_BEAUTY_EXPERT_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }