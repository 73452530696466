import React from 'react'

const MyInput = (props) => {
  return (
    <>
      <label htmlFor={props.id}>{props.titleName}</label>
      <input disabled={props.disabled==true? true : false} defaultValue={props.defaultVal} onChange={props.handleChangeFunc} onBlur={props.handleBlurFunc} id={props.id} name={props.name} className={props.inputType === "radio" || props.inputType==="checkbox" ? null : "form-control"} placeholder={props.placeholder} type={props.inputType} /> 
    </>
  )
}

export default MyInput
