export const ADD_SERVICE_ORDER_DETAIL_START = "ADD_SERVICE_ORDER_DETAIL_START";
export const ADD_SERVICE_ORDER_DETAIL_SUCCESS = "ADD_SERVICE_ORDER_DETAIL_SUCCESS";
export const ADD_SERVICE_ORDER_DETAIL_ERROR = "ADD_SERVICE_ORDER_DETAIL_ERROR";


export const GET_ALL_SERVICE_ORDER_DETAIL_START = "GET_ALL_SERVICE_ORDER_DETAIL_START";
export const GET_ALL_SERVICE_ORDER_DETAIL_SUCCESS = "GET_ALL_SERVICE_ORDER_DETAIL_SUCCESS";
export const GET_ALL_SERVICE_ORDER_DETAIL_ERROR = "GET_ALL_SERVICE_ORDER_DETAIL_ERROR";



export const DELETE_SERVICE_ORDER_DETAIL_START = "DELETE_SERVICE_ORDER_DETAIL_START";
export const DELETE_SERVICE_ORDER_DETAIL_SUCCESS = "DELETE_SERVICE_ORDER_DETAIL_SUCCESS";
export const DELETE_SERVICE_ORDER_DETAIL_ERROR = "DELETE_SERVICE_ORDER_DETAIL_ERROR";


