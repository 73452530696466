import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
const Header = () => {
  const logoutFunc = () =>{
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    window.location.reload()
  }
  return (
    <header>
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo">
            <img
              style={{ width: "300px" }}
              className="img-fluid"
              src="/images/logo.png"
              alt=""
            />
          </div>
          <nav className="navMenu">
            <ul className="d-flex align-items-center list-unstyled">
              <li>
                <Link to={'/'}>
                  <img
                    style={{ width: "40px" }}
                    className="img-fluid"
                    src="/images/home.png"
                    alt=""
                  />
                </Link>
              </li>
              <li>
              <button onClick={()=> logoutFunc()} style={{border:"none", outline:"none", backgroundColor:"transparent"}}>
                  <img
                    style={{ width: "40px" }}
                    className="img-fluid"
                    src="/images/logout.png"
                    alt=""
                  />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
