import React, { useEffect } from "react";
import SubHeader from "../../components/Header/SubHeader";
import DataTableComponent from "../../components/dataTable/DataTableComponent";
import { connect } from "react-redux";
import {
  deleteBeautyServiceAction,
  getBeautyServiceAllAction,
} from "../../redux/actions/beautyServiceAction";
import LoadingComponent from "../../components/loadingBox/LoadingComponent";
import EmptyTableComponent from "../../components/dataTable/EmptyTableComponent";
import ModifyButtonsComponent from "../../components/dataTable/ModifyButtonsComponent";
import { deleteFunc } from "../../constants/delete/DeleteForm";

const tableHeadData = ["Xidmət adı", "Xidmətə daxildir", "Qiymət", ""];

const BeautyService = (props) => {
  useEffect(() => {
    return props.getBeautyServiceAllAction();
  }, []);

  const getIdFunc = (id) => {
    const myData = {
      beautyServiceId: id
     }
    deleteFunc({ deleteActionName:(props.deleteBeautyServiceAction(myData)),locationReplaceName:"/beautyService"});
  };

  return (
    <div className="beautyServiceHome">
      <div className="beautyServiceHeader">
        <SubHeader
          backLink="/"
          title="Xidmətlər siyahısı"
          btnTitle="xidmət əlavə et"
          btnLink="/addBeautyService"
        />
      </div>
      <div className="beautyServiceBody">
        <div className="container">
          <div className="row">
            {props.getBeautyServices.isLoading ? (
              <LoadingComponent />
            ) : props.getBeautyServices.beautyServices?.data == null ? (
              <div className="col-12 colsm-12 col-md-12 col-lg-12 text-center my-5">
                <EmptyTableComponent />
              </div>
            ) : (
              <DataTableComponent tableHeadData={tableHeadData}>
                {props.getBeautyServices.beautyServices?.data?.map(
                  (item, index) => (
                    <tr key={index}>
                      <td>{item.serviceName}</td>
                      <td>{item.serviceDetail}</td>
                      <td>{item.servicePrice} Azn</td>
                    
                      <ModifyButtonsComponent
                          delFunc={() => getIdFunc(item.id)}
                        />
                    </tr>
                  )
                )}
              </DataTableComponent>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getBeautyServices: state.getBeautyServices,
    deleteBeautyService: state.deleteBeautyService,
  };
};

export default connect(mapStateToProps, {
  getBeautyServiceAllAction,
  deleteBeautyServiceAction,
})(BeautyService);
