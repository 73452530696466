import React from "react";

const EmptyTableComponent = () => {
  return (
    <div
      style={{ border: "1px solid #bbbb", padding: "50px" }}
      className="emptyTableComponent"
    >
      <img style={{ width: "300px" }} src="/images/notData.png" alt="" />
      <h1>Siyahı boşdur!</h1>
    </div>
  );
};

export default EmptyTableComponent;
