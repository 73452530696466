export const GET_BEAUTY_SERVICE_START = "GET_BEAUTY_SERVICE_START";
export const GET_BEAUTY_SERVICE_SUCCESS = "GET_BEAUTY_SERVICE_SUCCESS";
export const GET_BEAUTY_SERVICE_ERROR = "GET_BEAUTY_SERVICE_ERROR";

export const ADD_BEAUTY_SERVICE_START = "ADD_BEAUTY_SERVICE_START";
export const ADD_BEAUTY_SERVICE_SUCCESS = "ADD_BEAUTY_SERVICE_SUCCESS";
export const ADD_BEAUTY_SERVICE_ERROR = "ADD_BEAUTY_SERVICE_ERROR";

export const DELETE_BEAUTY_SERVICE_START = "DELETE_BEAUTY_SERVICE_START";
export const DELETE_BEAUTY_SERVICE_SUCCESS = "DELETE_BEAUTY_SERVICE_SUCCESS";
export const DELETE_BEAUTY_SERVICE_ERROR = "DELETE_BEAUTY_SERVICE_ERROR";

