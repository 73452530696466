export const ADD_RENT_ROOM_START = "ADD_OUTGOING_START";
export const ADD_RENT_ROOM_SUCCESS = "ADD_OUTGOING_SUCCESS";
export const ADD_RENT_ROOM_ERROR = "ADD_OUTGOING_ERROR";

export const GET_ALL_RENT_ROOM_START = "GET_ALL_RENT_ROOM_START";
export const GET_ALL_RENT_ROOM_SUCCESS = "GET_ALL_RENT_ROOM_SUCCESS";
export const GET_ALL_RENT_ROOM_ERROR = "GET_ALL_RENT_ROOM_ERROR";

export const DELETE_RENT_ROOM_START = "DELETE_RENT_ROOM_START";
export const DELETE_RENT_ROOM_SUCCESS = "DELETE_RENT_ROOM_SUCCESS";
export const DELETE_RENT_ROOM_ERROR = "DELETE_RENT_ROOM_ERROR";

export const UPDATE_RENT_ROOM_START = "UPDATE_RENT_ROOM_START";
export const UPDATE_RENT_ROOM_SUCCESS = "UPDATE_RENT_ROOM_SUCCESS";
export const UPDATE_RENT_ROOM_ERROR = "UPDATE_RENT_ROOM_ERROR";

export const GET_RENT_ROOM_START = "GET_RENT_ROOM_START";
export const GET_RENT_ROOM_SUCCESS = "GET_RENT_ROOM_SUCCESS";
export const GET_RENT_ROOM_ERROR = "GET_RENT_ROOM_ERROR";