import axios from "axios";
import {
  ADD_SERVICE_ORDER_ERROR,
  ADD_SERVICE_ORDER_START,
  DELETE_SERVICE_ORDER_ERROR,
  DELETE_SERVICE_ORDER_START,
  DELETE_SERVICE_ORDER_SUCCESS,
  GET_ALL_SERVICE_ORDER_ERROR,
  GET_ALL_SERVICE_ORDER_START,
  GET_ALL_SERVICE_ORDER_SUCCESS,
} from "../actionTypes/serviceOrderActionTypes";
import { baseUrl } from "../../api/baseApi";
import { config } from "../../api/configApi";
import { ADD_BEAUTY_EXPERT_SUCCESS } from "../actionTypes/beautyExpertActionTypes";
import { getToken } from "../../constants/role/getToken";

export const getAllServiceOrdersAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_SERVICE_ORDER_START,
  });
  const configGetAll = {
    headers: {
      Authorization: getToken,
    },
    params: {
      monthFilter: data.monthFilter,
    },
  };
  axios
    .get(`${baseUrl}/ServiceOrder/GetAllServiceOrders`, configGetAll)
    .then((response) => {
      dispatch({
        type: GET_ALL_SERVICE_ORDER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_SERVICE_ORDER_ERROR,
        payload: error,
      });
    });
};

export const addServiceOrderAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_SERVICE_ORDER_START,
  });

  axios
    .post(`${baseUrl}/ServiceOrder/AddServiceOrder`, data, config)
    .then((response) => {
      dispatch({
        type: ADD_BEAUTY_EXPERT_SUCCESS,
        payload: response.data,
      });
      window.location.replace("/allServiceOrder");
    })
    .catch((error) => {
      dispatch({
        type: ADD_SERVICE_ORDER_ERROR,
        payload: error,
      });
    });
};

export const deleteOrderServiceAction = (data) => (dispatch) => {
  const configDelete = {
    params: {
      serviceOrderId: data.serviceOrderId,
    },
    headers: {
      Authorization: getToken,
    },
  };

  dispatch({
    type: DELETE_SERVICE_ORDER_START,
  });

  axios
    .put(`${baseUrl}/ServiceOrder/DeleteServiceOrder`, null, configDelete)
    .then((response) => {
      dispatch({
        type: DELETE_SERVICE_ORDER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: DELETE_SERVICE_ORDER_ERROR,
        payload: error,
      });
    });
};
