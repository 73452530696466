import {
  ADD_SERVICE_ORDER_DETAIL_ERROR,
  ADD_SERVICE_ORDER_DETAIL_START,
  ADD_SERVICE_ORDER_DETAIL_SUCCESS,
  DELETE_SERVICE_ORDER_DETAIL_ERROR,
  DELETE_SERVICE_ORDER_DETAIL_START,
  DELETE_SERVICE_ORDER_DETAIL_SUCCESS,
  GET_ALL_SERVICE_ORDER_DETAIL_ERROR,
  GET_ALL_SERVICE_ORDER_DETAIL_START,
  GET_ALL_SERVICE_ORDER_DETAIL_SUCCESS,
} from "../actionTypes/serviceOrderDetailActionTypes";

const ADD_INITIAL_DATA = {
  serviceOrderDetail: {},
  isLoading: false,
  error: "ERROR THIS API",
};

export const addServiceOrderDetailReducer = (
  state = ADD_INITIAL_DATA,
  { type, payload }
) => {
  switch (type) {
    case ADD_SERVICE_ORDER_DETAIL_START:
      return { ...state, isLoading: true, error: "" };
    case ADD_SERVICE_ORDER_DETAIL_SUCCESS:
      return { ...state, serviceOrderDetail: payload, isLoading: false };
    case ADD_SERVICE_ORDER_DETAIL_ERROR:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};

const GET_ALL_INITIAL_DATA = {
  serviceOrderDetails: [],
  filter: [],
  isLoading: false,
  error: "ERROR THIS API",
};

export const getAllServiceOrderDetailsReducer = (
  state = GET_ALL_INITIAL_DATA,
  { type, payload }
) => {
  switch (type) {
    case GET_ALL_SERVICE_ORDER_DETAIL_START:
      return { ...state, error: "", isLoading: true };
    case GET_ALL_SERVICE_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        serviceOrderDetails: payload,
        filter: payload,
        isLoading: false,
      };
    case GET_ALL_SERVICE_ORDER_DETAIL_ERROR:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};


const DELETE_INITIAL_DATA = {
  serviceOrderDetailId:0, isLoading:false, error:"ERROR THIS API",
};

export const deleteServiceOrderDetailReducer = (state= DELETE_INITIAL_DATA, {type, payload}) => {
  switch(type){
    case DELETE_SERVICE_ORDER_DETAIL_START : return {...state, isLoading:true, error:""}
    case DELETE_SERVICE_ORDER_DETAIL_SUCCESS : return {...state, serviceOrderDetailId:payload, isLoading:false}
    case DELETE_SERVICE_ORDER_DETAIL_ERROR : return {...state, error:payload, isLoading:false}
    default : return state; 
  }
}
