import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Form from "../../components/form/Form";
import {  EditNote } from "@mui/icons-material";
import MyInput from "../../components/form/MyInput";
import ErrorText from "../../components/form/ErrorText";
import { useFormik } from "formik";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getItemCourseAction,
  updateCourseAction,
} from "../../redux/actions/courseAction";

const UpdateCourse = (s) => {
  const course = useSelector((state) => state.getCourse);
  const { courseId } = useParams();
  let getId = courseId;
  const [id, setId] = useState(0);

  useEffect(() => {
    setId(getId);

    return s.getItemCourseAction(id);
  }, [id]);

  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        id: id,
        educationName: "",
        teacherName: "",
        studentName: "",
        coursePrice: null,
        teacherPriceRate: null,
        teacherGainPrice: null,
        saloonGainPrice: null,
        courseStartedDate: null,
        courseCreatedDate: null,
        isDelete: null,
      },
      onSubmit: (values) => {
        const data = {
          id: id,
          educationName:
            values.educationName == "" || values.educationName == null
              ? course.courseId.data?.educationName
              : values.educationName,
          teacherName:
            values.teacherName == "" || values.teacherName == null
              ? course.courseId.data?.teacherName
              : values.teacherName,
          studentName:
            values.studentName == "" || values.studentName == null
              ? course.courseId.data?.studentName
              : values.studentName,
          coursePrice:
            values.coursePrice == "" || values.coursePrice == null
              ? course.courseId.data?.coursePrice
              : Number(values.coursePrice),
          teacherPriceRate:
            values.teacherPriceRate == "" || values.teacherPriceRate == null
              ? course.courseId.data?.teacherPriceRate
              : Number(values.teacherPriceRate),
          teacherGainPrice:
            values.teacherGainPrice == "" || values.teacherGainPrice == null
              ? course.courseId.data?.teacherGainPrice
              : values.teacherGainPrice,
          saloonGainPrice:
            values.teacherPriceRate == "" || values.teacherPriceRate == null
              ? course.courseId.data?.saloonGainPrice
              : values.saloonGainPrice,
          courseStartedDate:
            values.courseStartedDate == "" || values.courseStartedDate == null
              ? course.courseId.data?.courseStartedDate
              : values.courseStartedDate,
          courseCreatedDate:
            values.courseCreatedDate == "" || values.courseCreatedDate == null
              ? course.courseId.data?.courseCreatedDate
              : values.courseCreatedDate,
          isDelete:
            values.isDelete == "" || values.isDelete == null
              ? Boolean(course.courseId.data?.isDelete)
              : values.isDelete,
        };
        console.log(data);

        s.updateCourseAction(data);
      },
    }
  );
  return (
    <div className="updateCourse">
      <Header />
      <div className="addForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/addCourse.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Kursda dəyişiklik et"}
                btnClass="btn btn-success"
                btnTitle="Düzəliş et"
                btnIco={<EditNote />}
              >
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="educationName"
                  name="educationName"
                  titleName={"kurs"}
                  placeholder="Kurs adı"
                  inputType="text"
                  defaultVal={course.courseId.data?.educationName}
                />
                <ErrorText
                  error={errors.educationName}
                  touched={touched.educationName}
                  errorMessage={errors.educationName}
                  name={"educationName"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="teacherName"
                  name="teacherName"
                  titleName={"Müəllim"}
                  placeholder="Müəllim adı və soyadı"
                  inputType="text"
                  defaultVal={course.courseId.data?.teacherName}
                />
                <ErrorText
                  error={errors.teacherName}
                  touched={touched.teacherName}
                  errorMessage={errors.teacherName}
                  name={"teacherName"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="studentName"
                  name="studentName"
                  titleName={"Tələbə"}
                  placeholder="Tələbə adı və soyadı"
                  inputType="text"
                  defaultVal={course.courseId.data?.studentName}
                />
                <ErrorText
                  error={errors.studentName}
                  touched={touched.studentName}
                  errorMessage={errors.studentName}
                  name={"studentName"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="coursePrice"
                  name="coursePrice"
                  titleName={"qiymət"}
                  placeholder="Kursun qiyməti"
                  inputType="text"
                  defaultVal={course.courseId.data?.coursePrice}
                  disabled={true}
                />
                <ErrorText
                  error={errors.coursePrice}
                  touched={touched.coursePrice}
                  errorMessage={errors.coursePrice}
                  name={"coursePrice"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="teacherPriceRate"
                  name="teacherPriceRate"
                  titleName={"müəllim faizi"}
                  placeholder="Müəllimin faizi"
                  inputType="text"
                  defaultVal={course.courseId.data?.teacherPriceRate}
                  disabled={true}
                />
                <ErrorText
                  error={errors.teacherPriceRate}
                  touched={touched.teacherPriceRate}
                  errorMessage={errors.teacherPriceRate}
                  name={"teacherPriceRate"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="courseStartedDate"
                  name="courseStartedDate"
                  titleName={"Kurs başlama tarixi"}
                  inputType="date"
                  defaultVal={course.courseId.data?.courseStartedDate}
                />
                <ErrorText
                  error={errors.courseStartedDate}
                  touched={touched.courseStartedDate}
                  errorMessage={errors.courseStartedDate}
                  name={"courseStartedDate"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="courseCreatedDate"
                  name="courseCreatedDate"
                  titleName={"Kurs yaradılma tarixi"}
                  inputType="date"
                  defaultVal={course.courseId.data?.courseCreatedDate}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    updateCourse: state.updateCourse,
    getCourse: state.getCourse,
  };
};

export default connect(mapStateToProps, {
  updateCourseAction,
  getItemCourseAction,
})(UpdateCourse);
