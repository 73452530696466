import React from "react";
import Header from "../../components/Header/Header";
import Form from "../../components/form/Form";
import { AddTask } from "@mui/icons-material";
import MyInput from "../../components/form/MyInput";
import ErrorText from "../../components/form/ErrorText";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { addOutGoingValidation } from "../../constants/yup/validation";
import { addOutGoingAction } from "../../redux/actions/outGoingAction";

const AddOutGoing = (s) => {
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        outGoingName: "",
        description: "",
        outGoingMoney: 0,
        outGoingDate: new Date(),
        outGoingCreatedDate: new Date()
      },
      onSubmit: (values) => {
        const data = {
          outGoingName: values.outGoingName,
          description: values.description,
          outGoingMoney: values.outGoingMoney,
          outGoingDate: values.outGoingDate,
          outGoingCreatedDate: new Date()

        };
        s.addOutGoingAction(data);
      },
      validationSchema: addOutGoingValidation,
    }
  );
  return (
    <div className="addOutGoing">
      <Header />
      <div className="addForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/outgoing.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Yeni xərc əlavə et"}
                btnClass="btn btn-success"
                btnTitle="Əlavə et"
                btnIco={<AddTask />}
              >
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="outGoingName"
                  name="outGoingName"
                  titleName={"xərc"}
                  placeholder="xərc adı"
                  inputType="text"
                />
                <ErrorText
                  error={errors.outGoingName}
                  touched={touched.outGoingName}
                  errorMessage={errors.outGoingName}
                  name={"outGoingName"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="description"
                  name="description"
                  titleName={"ətraflı"}
                  placeholder="Xərc haqqında"
                  inputType="text"
                />
                <ErrorText
                  error={errors.description}
                  touched={touched.description}
                  errorMessage={errors.description}
                  name={"description"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="outGoingMoney"
                  name="outGoingMoney"
                  titleName={"qiymət"}
                  placeholder="xərcin qiyməti"
                  inputType="text"
                />
                <ErrorText
                  error={errors.outGoingMoney}
                  touched={touched.outGoingMoney}
                  errorMessage={errors.outGoingMoney}
                  name={"outGoingMoney"}
                />
                   <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="outGoingDate"
                  name="outGoingDate"
                  titleName={"xərclənmə tarixi"}
                  inputType="date"
                />
                <ErrorText
                  error={errors.outGoingDate}
                  touched={touched.outGoingDate}
                  errorMessage={errors.outGoingDate}
                  name={"outGoingDate"}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addService: state.addOutGoingAction,
  };
};

export default connect(mapStateToProps, { addOutGoingAction })(
  AddOutGoing
);
