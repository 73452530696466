import React from "react";
import Header from "../../components/Header/Header";
import Form from "../../components/form/Form";
import { AddTask } from "@mui/icons-material";
import MyInput from "../../components/form/MyInput";
import ErrorText from "../../components/form/ErrorText";
import { useFormik } from "formik";
import { connect } from "react-redux";
import {
  addRentRoomValidation,
} from "../../constants/yup/validation";
import { addRentRoomAction } from "../../redux/actions/rentRoomAction";

const AddRentRoom = (s) => {
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        roomNo: "",
        roomName: "",
        rentPrice: 0,
        rentDate: "",
      },
      onSubmit: (values) => {
        const data = {
          roomNo: values.roomNo,
          roomName: values.roomName,
          rentPrice: values.rentPrice,
          rentDate: values.rentDate,
        };
        s.addRentRoomAction(data);
      },
      validationSchema: addRentRoomValidation,
    }
  );
  return (
    <div className="addRentRoom">
      <Header />
      <div className="addForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/rent.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Yeni icarə əlavə et"}
                btnClass="btn btn-success"
                btnTitle="Əlavə et"
                btnIco={<AddTask />}
              >
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="roomNo"
                  name="roomNo"
                  titleName={"otaq no"}
                  placeholder="otaq nömrəsi"
                  inputType="text"
                />
                <ErrorText
                  error={errors.roomNo}
                  touched={touched.roomNo}
                  errorMessage={errors.roomNo}
                  name={"roomNo"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="roomName"
                  name="roomName"
                  titleName={"otaq adı"}
                  placeholder="otaq adı"
                  inputType="text"
                />
                <ErrorText
                  error={errors.roomName}
                  touched={touched.roomName}
                  errorMessage={errors.roomName}
                  name={"roomName"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="rentPrice"
                  name="rentPrice"
                  titleName={"qiymət"}
                  placeholder="icarənin qiyməti"
                  inputType="text"
                />
                <ErrorText
                  error={errors.rentPrice}
                  touched={touched.rentPrice}
                  errorMessage={errors.rentPrice}
                  name={"rentPrice"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="rentDate"
                  name="rentDate"
                  titleName={"icarə müddəti"}
                  placeholder={"icarə müddəti"}
                  inputType="text"
                />
                <ErrorText
                  error={errors.rentDate}
                  touched={touched.rentDate}
                  errorMessage={errors.rentDate}
                  name={"rentDate"}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addRentRoom: state.addRentRoomAction,
  };
};

export default connect(mapStateToProps, { addRentRoomAction })(AddRentRoom);
