import axios from "axios";
import {
  ADD_BEAUTY_SERVICE_ERROR,
  ADD_BEAUTY_SERVICE_START,
  ADD_BEAUTY_SERVICE_SUCCESS,
  DELETE_BEAUTY_SERVICE_ERROR,
  DELETE_BEAUTY_SERVICE_START,
  DELETE_BEAUTY_SERVICE_SUCCESS,
  GET_BEAUTY_SERVICE_ERROR,
  GET_BEAUTY_SERVICE_START,
  GET_BEAUTY_SERVICE_SUCCESS,
} from "../actionTypes/beautyServiceActionTypes";
import { baseUrl } from "../../api/baseApi";
import { getToken } from "../../constants/role/getToken";

export const getBeautyServiceAllAction = () => (dispatch) => {
  const config = {
    headers: {
      Authorization: getToken,
    },
  };

  dispatch({
    type: GET_BEAUTY_SERVICE_START,
  });

  axios
    .get(`${baseUrl}/BeautyService/GetAllBeautyServices`, config)
    .then((response) => {
      dispatch({
        type: GET_BEAUTY_SERVICE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_BEAUTY_SERVICE_ERROR,
        payload: error,
      });
    });
};

export const addBeautyServiceAction = (data) => (dispatch) => {
  const config = {
    headers: {
      Authorization: getToken,
    },
  };

  dispatch({
    type: ADD_BEAUTY_SERVICE_START,
  });

  axios
    .post(`${baseUrl}/BeautyService/AddBeautyService`, data, config)
    .then((response) => {
      dispatch({
        type: ADD_BEAUTY_SERVICE_SUCCESS,
        payload: response.data,
      });
      window.location.replace("/beautyService");
    })
    .catch((error) => {
      dispatch({
        type: ADD_BEAUTY_SERVICE_ERROR,
        payload: error,
      });
    });
};

export const deleteBeautyServiceAction = (data) => (dispatch) => {
  const configDelete = {
    params: {
      beautyServiceId: data.beautyServiceId,
    },
    headers: {
      Authorization: getToken,
    },
  };

  dispatch({
    type: DELETE_BEAUTY_SERVICE_START,
  });

  axios
    .put(`${baseUrl}/BeautyService/DeleteBeautyService`, null, configDelete)
    .then((response) => {
      dispatch({
        type: DELETE_BEAUTY_SERVICE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: DELETE_BEAUTY_SERVICE_ERROR,
        payload: error,
      });
    });
};
