import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Form from "../../components/form/Form";
import {EditNote } from "@mui/icons-material";
import MyInput from "../../components/form/MyInput";
import ErrorText from "../../components/form/ErrorText";
import { useFormik } from "formik";
import { connect, useSelector } from "react-redux";
import {
  getItemDeptAction,
  updateDeptAction,
} from "../../redux/actions/deptAction";
import { useParams } from "react-router";

const UpdateDept = (s) => {
  const dept = useSelector((state) => state.getDept);
  const { deptId } = useParams();
  let getId = deptId;
  const [id, setId] = useState(0);

  useEffect(() => {
    setId(getId);

    return s.getItemDeptAction(id);
  }, [id]);

  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        id: id,
        deptName: "",
        deptPrice: null,
        isRefunded: null,
        deptDate: null,
        deptCreatedDate: null,
        isDelete: null,
      },
      onSubmit: (values) => {
        const data = {
          id: id,
          deptName:
            values.deptName == "" || values.deptName == null
              ? dept.deptId.data?.deptName
              : values.deptName,
          deptPrice:
            values.deptPrice == "" || values.deptPrice == null
              ? dept.deptId.data?.deptPrice
              : values.deptPrice,
          isRefunded:
            values.isRefunded == "" || values.isRefunded == null
              ? Boolean(dept.deptId.data?.isRefunded)
              : values.isRefunded,
          deptDate:
            values.deptDate == "" || values.deptDate == null
              ? dept.deptId.data?.deptDate
              : values.deptDate,
          deptCreatedDate:
            values.deptCreatedDate == "" || values.deptCreatedDate == null
              ? dept.deptId.data?.deptCreatedDate
              : values.deptCreatedDate,
          isDelete:
            values.isDelete == "" || values.isDelete == null
              ? Boolean(dept.deptId.data?.isDelete)
              : values.isDelete,
        };
        console.log(data);

        s.updateDeptAction(data);
      },
    }
  );
  return (
    <div className="addDept">
      <Header />
      <div className="addForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/addCourse.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Borcda dəyişiklik et"}
                btnClass="btn btn-success"
                btnTitle="Düzəliş et"
                btnIco={<EditNote />}
              >
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="deptName"
                  name="deptName"
                  titleName={"borc"}
                  placeholder="Borc adı"
                  inputType="text"
                  defaultVal={dept.deptId.data?.deptName}
                />
                <ErrorText
                  error={errors.deptName}
                  touched={touched.deptName}
                  errorMessage={errors.deptName}
                  name={"deptName"}
                />

                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="deptPrice"
                  name="deptPrice"
                  titleName={"qiymət"}
                  placeholder="Borcun dəyəri"
                  inputType="text"
                  defaultVal={dept.deptId.data?.deptPrice}
                />
                <ErrorText
                  error={errors.deptPrice}
                  touched={touched.deptPrice}
                  errorMessage={errors.deptPrice}
                  name={"deptPrice"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="isRefunded"
                  name="isRefunded"
                  titleName={"qaytarılıb"}
                  inputType="checkbox"
                  defaultVal={dept.deptId.data?.isRefunded}
                />
                <ErrorText
                  error={errors.isRefunded}
                  touched={touched.isRefunded}
                  errorMessage={errors.isRefunded}
                  name={"isRefunded"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="deptDate"
                  name="deptDate"
                  titleName={"Borcun alınma tarixi"}
                  inputType="date"
                  defaultVal={dept.deptId.data?.deptDate}
                />

                <ErrorText
                  error={errors.deptDate}
                  touched={touched.deptDate}
                  errorMessage={errors.deptDate}
                  name={"deptDate"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="deptCreatedDate"
                  name="deptCreatedDate"
                  titleName={"Borcun yaranma tarixi"}
                  inputType="date"
                  defaultVal={dept.deptId.data?.deptCreatedDate}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    updateDept: state.updateDept,
    getDept: state.getDept,
  };
};

export default connect(mapStateToProps, {
  updateDeptAction,
  getItemDeptAction,
})(UpdateDept);
