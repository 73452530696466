import { GET_ALL_PAYMENT_METHOD_ERROR, GET_ALL_PAYMENT_METHOD_START, GET_ALL_PAYMENT_METHOD_SUCCESS } from "../actionTypes/paymentMethodActionTypes";

const ALL_INITIAL_DATA = {
    paymentMethods: [],
    isLoading: false,
    error: "ERROR THIS API",
  };
  
  export const getAllPaymentMethodReducer = (
    state = ALL_INITIAL_DATA,
    { type, payload }
  ) => {
    switch (type) {
      case GET_ALL_PAYMENT_METHOD_START:
        return { ...state, isLoading: true, error: "" };
      case GET_ALL_PAYMENT_METHOD_SUCCESS:
        return { ...state, paymentMethods: payload, isLoading: false };
      case GET_ALL_PAYMENT_METHOD_ERROR:
        return { ...state, error: payload, isLoading: false };
      default:
        return state;
    }
  };
  