import axios from "axios";
import {
  ADD_OUTGOING_ERROR,
  ADD_OUTGOING_START,
  ADD_OUTGOING_SUCCESS,
  DELETE_OUTGOING_ERROR,
  DELETE_OUTGOING_START,
  DELETE_OUTGOING_SUCCESS,
  GET_ALL_OUTGOING_ERROR,
  GET_ALL_OUTGOING_START,
  GET_ALL_OUTGOING_SUCCESS,
  GET_OUTGOING_ERROR,
  GET_OUTGOING_START,
  GET_OUTGOING_SUCCESS,
  UPDATE_OUTGOING_ERROR,
  UPDATE_OUTGOING_START,
  UPDATE_OUTGOING_SUCCESS,
} from "../actionTypes/outGoingActionTypes";
import { baseUrl } from "../../api/baseApi";
import { config } from "../../api/configApi";
import { getToken } from "../../constants/role/getToken";

export const addOutGoingAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_OUTGOING_START,
  });

  axios
    .post(`${baseUrl}/OutGoing/outGoingAdd`, data, config)
    .then((response) => {
      dispatch({
        type: ADD_OUTGOING_SUCCESS,
        payload: response.data,
      });
      window.location.replace('/allOutGoings');
    })
    .catch((error) => {
      dispatch({
        type: ADD_OUTGOING_ERROR,
        payload: error,
      });
    });
};

export const getAllOutGoingAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_OUTGOING_START,
  });
  const configGetAll = {
    headers: {
      Authorization: getToken,
    },
    params: {
      monthFilter: data.monthFilter,
    },
  };
  axios
    .get(`${baseUrl}/OutGoing/outGoingGetAll`, configGetAll)
    .then((response) => {
      dispatch({
        type: GET_ALL_OUTGOING_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_OUTGOING_ERROR,
        payload: error,
      });
    });
};

export const deleteOutGoingAction = (data) => (dispatch) => {
  const configDelete = {
    params: {
      outGoingId: data.outGoingId
    },
    headers: {
      Authorization: getToken,
    }
   
  };

  dispatch({
    type: DELETE_OUTGOING_START,
  });


  axios.put(`${baseUrl}/OutGoing/DeleteOutGoing`,null, configDelete).then((response)=>{
    dispatch({
      type: DELETE_OUTGOING_SUCCESS,
      payload: response.data,
    });
  }).catch((error)=>{
    dispatch({
      type: DELETE_OUTGOING_ERROR,
      payload:error
    })
  });
}

export const getItemOutGoingAction = (outGoingId) => (dispatch) => {
 
  dispatch({
    type: GET_OUTGOING_START,
  });

  axios
    .get(`${baseUrl}/OutGoing/GetOutGoing/${outGoingId}`, null, config)
    .then((response) => {
      dispatch({
        type: GET_OUTGOING_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_OUTGOING_ERROR,
        payload: error,
      });
    });
};


export const updateOutGoingAction = (data) => (dispatch) => {

  dispatch({
    type: UPDATE_OUTGOING_START,
  });

  axios
    .post(`${baseUrl}/OutGoing/UpdateOutGoing`, data, config)
    .then((response) => {
      dispatch({
        type: UPDATE_OUTGOING_SUCCESS,
        payload: response.data,
      });
      window.location.replace("/allOutGoings");
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_OUTGOING_ERROR,
        payload: error,
      });
    });
};
