import axios from "axios";
import {
  ADD_BEAUTY_EXPERT_TO_SERVICE_ERROR,
  ADD_BEAUTY_EXPERT_TO_SERVICE_START,
  ADD_BEAUTY_EXPERT_TO_SERVICE_SUCCESS,
  GET_ALL_BEAUTY_EXPERT_TO_SERVICE_ERROR,
  GET_ALL_BEAUTY_EXPERT_TO_SERVICE_START,
  GET_ALL_BEAUTY_EXPERT_TO_SERVICE_SUCCESS,
} from "../actionTypes/beautyExpertToServiceActionTypes";
import { baseUrl } from "../../api/baseApi";
import { config } from "../../api/configApi";

export const addBeautyExpertToServiceAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_BEAUTY_EXPERT_TO_SERVICE_START,
  });
  axios
    .post(
      `${baseUrl}/BeautyExpertToService/beautyExpertToServiceAdd`,
      data,
      config
    )
    .then((response) => {
      dispatch({
        type: ADD_BEAUTY_EXPERT_TO_SERVICE_SUCCESS,
        payload: response.data,
      });
      window.location.replace('/allBeautyExpertToService')
    })
    .catch((error) => {
      dispatch({
        type: ADD_BEAUTY_EXPERT_TO_SERVICE_ERROR,
        payload: error,
      });
    });
};

export const getAllExpertToServices = () => (dispatch) => {
  dispatch({
    type: GET_ALL_BEAUTY_EXPERT_TO_SERVICE_START,
  });

  axios
    .get(`${baseUrl}/BeautyExpertToService/GetAllExpertToServices`, config)
    .then((response) => {
      dispatch({
        type: GET_ALL_BEAUTY_EXPERT_TO_SERVICE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_BEAUTY_EXPERT_TO_SERVICE_ERROR,
        payload: error,
      });
    });
};
