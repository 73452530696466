
import { ADD_RENT_ROOM_ERROR, ADD_RENT_ROOM_START, ADD_RENT_ROOM_SUCCESS, DELETE_RENT_ROOM_ERROR, DELETE_RENT_ROOM_START, DELETE_RENT_ROOM_SUCCESS, GET_ALL_RENT_ROOM_ERROR, GET_ALL_RENT_ROOM_START, GET_ALL_RENT_ROOM_SUCCESS, GET_RENT_ROOM_ERROR, GET_RENT_ROOM_START, GET_RENT_ROOM_SUCCESS, UPDATE_RENT_ROOM_ERROR, UPDATE_RENT_ROOM_START, UPDATE_RENT_ROOM_SUCCESS } from "../actionTypes/rentRoomActionTypes";
  
  const ADD_INITIAL_DATA = {
    rentRoom: {},
    isLoading: false,
    error: "ERROR THIS API",
  };
  
  export const addRentRoomReducer = (
    state = ADD_INITIAL_DATA,
    { type, payload }
  ) => {
    switch (type) {
      case ADD_RENT_ROOM_START:
        return { ...state, isLoading: true, error: "" };
      case ADD_RENT_ROOM_SUCCESS:
        return { ...state,  rentRoom: payload, isLoading: false };
      case ADD_RENT_ROOM_ERROR:
        return { ...state, error: payload, isLoading: false };
      default:
        return state;
    }
  };
  
  const ALL_INITIAL_DATA = {
    rentRooms: [],
    isLoading: false,
    error: "ERROR THIS API",
  };
  
  export const getAllRentRoomReducer = (
    state = ALL_INITIAL_DATA,
    { type, payload }
  ) => {
    switch (type) {
      case GET_ALL_RENT_ROOM_START:
        return { ...state, isLoading: true, error: "" };
      case GET_ALL_RENT_ROOM_SUCCESS:
        return { ...state, rentRooms: payload, isLoading: false };
      case GET_ALL_RENT_ROOM_ERROR:
        return { ...state, error: payload, isLoading: false };
      default:
        return state;
    }
  };
  
  const DELETE_INITIAL_DATA = {
    rentRoomId:0, isLoading:false, error:"ERROR THIS API",
  };
  
  export const deleteRentRoomReducer = (state= DELETE_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case DELETE_RENT_ROOM_START : return {...state, isLoading:true, error:""}
      case DELETE_RENT_ROOM_SUCCESS : return {...state, rentRoomId:payload, isLoading:false}
      case DELETE_RENT_ROOM_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }
  
  const GET_INITIAL_DATA = {
    rentRoomId:0, isLoading:false, error:"ERROR THIS API",
  };
  
  export const getRentRoomReducer = (state= GET_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case GET_RENT_ROOM_START : return {...state, isLoading:true, error:""}
      case GET_RENT_ROOM_SUCCESS : return {...state, rentRoomId:payload, isLoading:false}
      case GET_RENT_ROOM_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }
  
  const UPDATE_INITIAL_DATA = {
   rentRoom:{}, isLoading:false, error:"ERROR THIS API",
  };
  
  export const updateRentRoomReducer = (state= UPDATE_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case UPDATE_RENT_ROOM_START : return {...state, isLoading:true, error:""}
      case UPDATE_RENT_ROOM_SUCCESS : return {...state, rentRoom:payload, isLoading:false}
      case UPDATE_RENT_ROOM_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }