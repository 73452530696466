import axios from "axios";
import {
  ADD_SERVICE_ORDER_DETAIL_ERROR,
  ADD_SERVICE_ORDER_DETAIL_START,
  ADD_SERVICE_ORDER_DETAIL_SUCCESS,
  DELETE_SERVICE_ORDER_DETAIL_ERROR,
  DELETE_SERVICE_ORDER_DETAIL_START,
  DELETE_SERVICE_ORDER_DETAIL_SUCCESS,
  GET_ALL_SERVICE_ORDER_DETAIL_ERROR,
  GET_ALL_SERVICE_ORDER_DETAIL_START,
  GET_ALL_SERVICE_ORDER_DETAIL_SUCCESS,
} from "../actionTypes/serviceOrderDetailActionTypes";
import { baseUrl } from "../../api/baseApi";
import { config } from "../../api/configApi";
import { getToken } from "../../constants/role/getToken";

export const addServiceOrderDetailAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_SERVICE_ORDER_DETAIL_START,
  });
  axios
    .post(`${baseUrl}/ServiceOrderDetail/AddServiceOrderDetail`, data, config)
    .then((response) => {
      dispatch({
        type: ADD_SERVICE_ORDER_DETAIL_SUCCESS,
        payload: response.data,
      });
      window.location.replace("/allServiceOrderDetails");
    })
    .catch((error) => {
      dispatch({
        type: ADD_SERVICE_ORDER_DETAIL_ERROR,
        payload: error,
      });
    });
};

export const getAllServiceOrderDetailAction = (data) => (dispatch) => {
  const configGetAll = {
    headers: {
      Authorization: getToken,
    },
    params: {
      take: data.take,
      expertName: data.expertName,
      filterDate: data.filterDate,
    },
  };
  dispatch({
    type: GET_ALL_SERVICE_ORDER_DETAIL_START,
  });

  axios
    .get(
      `${baseUrl}/ServiceOrderDetail/getAllServiceOrderDetails`,
      configGetAll
    )
    .then((response) => {
      dispatch({
        type: GET_ALL_SERVICE_ORDER_DETAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_SERVICE_ORDER_DETAIL_ERROR,
        payload: error,
      });
    });
};

export const deleteOrderDetailServiceAction = (data) => (dispatch) => {
  const configDelete = {
    params: {
      serviceOrderDetailId: data.serviceOrderDetailId,
    },
    headers: {
      Authorization: getToken,
    },
  };

  dispatch({
    type: DELETE_SERVICE_ORDER_DETAIL_START,
  });

  axios
    .put(
      `${baseUrl}/ServiceOrderDetail/DeleteServiceOrderDetail`,
      null,
      configDelete
    )
    .then((response) => {
      dispatch({
        type: DELETE_SERVICE_ORDER_DETAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: DELETE_SERVICE_ORDER_DETAIL_ERROR,
        payload: error,
      });
    });
};
