export const ADD_OUTGOING_START = "ADD_OUTGOING_START";
export const ADD_OUTGOING_SUCCESS = "ADD_OUTGOING_SUCCESS";
export const ADD_OUTGOING_ERROR = "ADD_OUTGOING_ERROR";



export const GET_ALL_OUTGOING_START = "GET_ALL_OUTGOING_START";
export const GET_ALL_OUTGOING_SUCCESS = "GET_ALL_OUTGOING_SUCCESS";
export const GET_ALL_OUTGOING_ERROR = "GET_ALL_OUTGOING_ERROR";

export const DELETE_OUTGOING_START = "DELETE_OUTGOING_START";
export const DELETE_OUTGOING_SUCCESS = "DELETE_OUTGOING_SUCCESS";
export const DELETE_OUTGOING_ERROR = "DELETE_OUTGOING_ERROR";


export const UPDATE_OUTGOING_START = "UPDATE_OUTGOING_START";
export const UPDATE_OUTGOING_SUCCESS = "UPDATE_OUTGOING_SUCCESS";
export const UPDATE_OUTGOING_ERROR = "UPDATE_OUTGOING_ERROR";

export const GET_OUTGOING_START = "GET_OUTGOING_START";
export const GET_OUTGOING_SUCCESS = "GET_OUTGOING_SUCCESS";
export const GET_OUTGOING_ERROR = "GET_OUTGOING_ERROR";