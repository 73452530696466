import {
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_START,
  AUTH_LOGIN_SUCCESS,
} from "../actionTypes/authActionTypes";

const INITIAL_STATE = {
  user: {},
  expiration: null,
  isLoading: false,
  error: "THIS API ERROR",
};

export const authLoginReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case AUTH_LOGIN_START:
      return { ...state, isLoading: true, error: "" };
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        user: payload,
        expiration: payload.expiration,
        isLoading: false,
      };
    case AUTH_LOGIN_ERROR:
      return { ...state, error: payload, isLoading: false };

    default:
      return state;
  }
};


