import { GET_CUSTOMERS_ERROR, GET_CUSTOMERS_START, GET_CUSTOMERS_SUCCESS, POST_CUSTOMER_ADD_ERROR, POST_CUSTOMER_ADD_START, POST_CUSTOMER_ADD_SUCCESS } from "../actionTypes/customerActionTypes";


const INITIAL_STATE = {
    customers:[], filter:[], isLoading : false, error: "ERROR THIS API"
}
export const customerReducer =(state=INITIAL_STATE, {type, payload}) =>{
    switch (type) {
        case GET_CUSTOMERS_START: return {...state, error:"", isLoading:true}
        case GET_CUSTOMERS_SUCCESS: return {...state, customers:payload, filter:payload, isLoading:false}
        case GET_CUSTOMERS_ERROR: return {...state, error:payload, isLoading:false} 
    
        default: return state;
           
    }
}

const ADD_INITIAL_STATE = {
    customer:{}, isLoading:false, error:"ERROR THIS API"
}

export const customerAddReducer = (state = ADD_INITIAL_STATE, {type, payload})=>{
    switch(type){
        case POST_CUSTOMER_ADD_START : return {...state, error:"", isLoading:true}
        case POST_CUSTOMER_ADD_SUCCESS : return {...state, customer:payload, isLoading:false}
        case POST_CUSTOMER_ADD_ERROR : return {...state, error:payload, isLoading:false}
        default : return state
    }
}