

import axios from "axios";
import { baseUrl } from "../../api/baseApi";
import { config } from "../../api/configApi";
import { getToken } from "../../constants/role/getToken";
import { ADD_COURSE_ERROR, ADD_COURSE_START, ADD_COURSE_SUCCESS, DELETE_COURSE_ERROR, DELETE_COURSE_START, DELETE_COURSE_SUCCESS, GET_ALL_COURSE_ERROR, GET_ALL_COURSE_START, GET_ALL_COURSE_SUCCESS, GET_COURSE_ERROR, GET_COURSE_START, GET_COURSE_SUCCESS, UPDATE_COURSE_ERROR, UPDATE_COURSE_START, UPDATE_COURSE_SUCCESS } from "../actionTypes/courseActionsType";

export const addCourseAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_COURSE_START,
  });

  axios
    .post(`${baseUrl}/Course/AddCourse`, data, config)
    .then((response) => {
      dispatch({
        type: ADD_COURSE_SUCCESS,
        payload: response.data,
      });
      window.location.replace('/allCourses');
    })
    .catch((error) => {
      dispatch({
        type: ADD_COURSE_ERROR,
        payload: error,
      });
    });
};

export const getAllCourseAction = (data) => (dispatch) => {
  dispatch({
    type:GET_ALL_COURSE_START,
  });
  const configGetAll = {
    headers: {
      Authorization: getToken,
    },
    params: {
      monthFilter: data.monthFilter,
    },
  };
  axios
    .get(`${baseUrl}/Course/GetAllCourses`, configGetAll)
    .then((response) => {
      dispatch({
        type: GET_ALL_COURSE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_COURSE_ERROR,
        payload: error,
      });
    });
};

export const deleteCourseAction = (data) => (dispatch) => {
  const configDelete = {
    params: {
      courseId: data.courseId
    },
    headers: {
      Authorization: getToken,
    }
   
  };

  dispatch({
    type: DELETE_COURSE_START,
  });


  axios.put(`${baseUrl}/Course/DeleteCourse`,null, configDelete).then((response)=>{
    dispatch({
      type: DELETE_COURSE_SUCCESS,
      payload: response.data,
    });

  }).catch((error)=>{
    dispatch({
      type: DELETE_COURSE_ERROR,
      payload:error
    })
  });
}


export const getItemCourseAction = (courseId) => (dispatch) => {
 
  dispatch({
    type: GET_COURSE_START,
  });

  axios
    .get(`${baseUrl}/Course/GetCourse/${courseId}`, null, config)
    .then((response) => {
      dispatch({
        type: GET_COURSE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_COURSE_ERROR,
        payload: error,
      });
    });
};


export const updateCourseAction = (data) => (dispatch) => {

  dispatch({
    type: UPDATE_COURSE_START,
  });

  axios
    .post(`${baseUrl}/Course/UpdateCourse`, data, config)
    .then((response) => {
      dispatch({
        type: UPDATE_COURSE_SUCCESS,
        payload: response.data,
      });
      window.location.replace("/allCourses");
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_COURSE_ERROR,
        payload: error,
      });
    });
};
