import HomePage from "../pages/HomePage";
import CustomerPage from "../pages/customer/CustomerPage";
import LoginPage from "../pages/LoginPage";
import ProtectedRoute, { ProtectedAdminRoute } from "./ProtectedRoute";
import { createBrowserRouter } from "react-router-dom";
import AddCustomerPage from "../pages/customer/AddCustomerPage";
import BeautyExpert from "../pages/beautyExpert/BeautyExpert";
import AddBeautyExpert from "../pages/beautyExpert/AddBeautyExpert";
import BeautyService from "../pages/beautyService/BeautyService";
import BeautyExpertToServicePage from "../pages/beautyExpertToService/BeautyExpertToServicePage";
import AllExpertToService from "../pages/beautyExpertToService/AllExpertToService";
import BeautyServiceAdd from "../pages/beautyService/BeautyServiceAdd";
import ServiceOrder from "../pages/serviceOrder/ServiceOrder";
import ServiceOrderAdd from "../pages/serviceOrder/ServiceOrderAdd";
import ServiceOrderDetailAdd from "../pages/serviceOrder/ServiceOrderDetailAdd";
import ServiceOrderDetails from "../pages/serviceOrder/ServiceOrderDetails";
import OutGoing from "../pages/outGoing/OutGoing";
import AddOutGoing from "../pages/outGoing/AddOutGoing";
import RentRoom from "../pages/rentRoom/RentRoom";
import AddRentRoom from "../pages/rentRoom/AddRentRoom";
import StatisticPage from "../pages/statistic/StatisticPage";
import { getTokenRoles, getRoleAdmin } from "../constants/role/getToken";
import Forbidden from "../pages/Forbidden";
import NotFound from "../pages/NotFound";
import CoursePage from "../pages/course/CoursePage";
import AddCourse from "../pages/course/AddCourse";
import DeptPage from "../pages/dept/DeptPage";
import AddDept from "../pages/dept/AddDept";
import UpdateDept from "../pages/dept/UpdateDept";
import UpdateCourse from "../pages/course/UpdateCourse";
import UpdateOutGoing from "../pages/outGoing/UpdateOutGoing";
import UpdateRentRoom from "../pages/rentRoom/UpdateRentRoom";

var token = localStorage.getItem('token');
const isAuthenticated = () => {
  return !!getTokenRoles();
};
const isRoleAuthenticatedAdmin = () => {
  return !!getRoleAdmin();
}
 const MainRoute =  createBrowserRouter([
  {
    
    path: "/login",
    element: token!=null ? <HomePage/> : <LoginPage />,
    index: true,
  },
  {
    element: <ProtectedRoute isAuthenticated={isAuthenticated()} />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/customer",
        element: <CustomerPage />,
      },
      {
        path: "/AddCustomer",
        element: <AddCustomerPage/>,
      },
      {
        path: "/beautyExpert",
        element: <BeautyExpert/>,
      },
      {
        path: "/AddBeautyExpert",
        element: <AddBeautyExpert/>,
      },
      {
        path: "/beautyService",
        element: <BeautyService/>,
      },
      {
        path: "/addBeautyService",
        element: <BeautyServiceAdd/>,
      },
      {
        path: "/addBeautyExpertToService",
        element: <BeautyExpertToServicePage/>,
      },
      {
        path: "/allBeautyExpertToService",
        element: <AllExpertToService/>,
      },
      {
        path: "/allServiceOrder",
        element: <ServiceOrder/>,
      },
      {
        path: "/serviceOrderAdd",
        element: <ServiceOrderAdd/>,
      },
      {
        path: "/serviceOrderDetailAdd",
        element: <ServiceOrderDetailAdd/>,
      },
      {
        path: "/allServiceOrderDetails",
        element: <ServiceOrderDetails/>,
      },
      {
        path: "/allOutGoings",
        element: <OutGoing/>,
      },
      {
        path: "/addOutGoing",
        element: <AddOutGoing/>,
      },
      {
        path: "/allRentRoom",
        element: <RentRoom/>,
      },
      {
        path: "/addRentRoom",
        element: <AddRentRoom/>,
      },
      {
        path: "/AddCourse",
        element: <AddCourse/>,
      },
      {
        path: "/AddDept",
        element: <AddDept/>,
      },
      {
        path: "/AllDept",
        element: <DeptPage/>,
      },
      {
        path: "/allCourses",
        element: <CoursePage/>,
      },
      
    ],
  },
  {
    element: (<ProtectedAdminRoute isAuthenticated={isAuthenticated()}  isAuthenticatedAdmin={isRoleAuthenticatedAdmin()} />),
    children: [
      {
        path: "/statisticAll",
        element: <StatisticPage/>,
      },
      {
        path: `/UpdateDept/:deptId`,
        element: <UpdateDept/>,
      },
      {
        path: `/UpdateCourse/:courseId`,
        element: <UpdateCourse/>,
      },
      {
        path: `/UpdateOutGoing/:outGoingId`,
        element: <UpdateOutGoing/>,
      },
      {
        path: `/UpdateRentRoom/:rentRoomId`,
        element: <UpdateRentRoom/>,
      },
    
    ],
  },
  {
    path: "*",
    element: <NotFound/>,
  },
  {
    path: "/Forbidden",
    element: <Forbidden/>,
  },
]);
export default MainRoute