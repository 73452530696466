export const ADD_DEPT_START = "ADD_DEPT_START";
export const ADD_DEPT_SUCCESS = "ADD_DEPT_SUCCESS";
export const ADD_DEPT_ERROR = "ADD_DEPT_ERROR";

export const GET_ALL_DEPT_START = "GET_ALL_DEPT_START";
export const GET_ALL_DEPT_SUCCESS = "GET_ALL_DEPT_SUCCESS";
export const GET_ALL_DEPT_ERROR = "GET_ALL_DEPT_ERROR";

export const DELETE_DEPT_START = "DELETE_DEPT_START";
export const DELETE_DEPT_SUCCESS = "DELETE_DEPT_SUCCESS";
export const DELETE_DEPT_ERROR = "DELETE_DEPT_ERROR";

export const UPDATE_DEPT_START = "UPDATE_DEPT_START";
export const UPDATE_DEPT_SUCCESS = "UPDATE_DEPT_SUCCESS";
export const UPDATE_DEPT_ERROR = "UPDATE_DEPT_ERROR";

export const GET_DEPT_START = "GET_DEPT_START";
export const GET_DEPT_SUCCESS = "GET_DEPT_SUCCESS";
export const GET_DEPT_ERROR = "GET_DEPT_ERROR";