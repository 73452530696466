import React, { useEffect } from "react";
import "../../assets/scss/beautyExpert.scss";
import "../../components/customer/customer.scss";
import { connect } from "react-redux";
import {
  deleteBeautyExpertAction,
  getBeautyExpertAllAction,
} from "../../redux/actions/beautyExpertAction";
import SubHeader from "../../components/Header/SubHeader";
import DataTableComponent from "../../components/dataTable/DataTableComponent";
import LoadingComponent from "../../components/loadingBox/LoadingComponent";
import EmptyTableComponent from "../../components/dataTable/EmptyTableComponent";
import ModifyButtonsComponent from "../../components/dataTable/ModifyButtonsComponent";
import { deleteFunc } from "../../constants/delete/DeleteForm";

const BeautyExpert = (props) => {
  const tableHeadData = [
    "No",
    "Ad",
    "Soyad",
    "İxtisas",
    "Telefon",
    "İş faizi",
    "",
  ];

  useEffect(() => {
    return props.getBeautyExpertAllAction();
  }, []);
  const getIdFunc = (id) => {
    const myData = {
      beautyExpertId: id
     }
    deleteFunc({ deleteActionName:(props.deleteBeautyExpertAction(myData)),locationReplaceName:"/beautyExpert"});
  };
  return (
    <div className="customerList">
      <SubHeader
        backLink="/"
        title="Expert siyahısı"
        btnTitle="expert əlavə et"
        btnLink="/addBeautyExpert"
      />
      <div className="container">
        <div className="customersTable">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                {props.getBeautyExperts.isLoading ? (
                  <LoadingComponent />
                ) : props.getBeautyExperts.beautyExperts?.data == null ? (
                  <div className="text-center">
                    <EmptyTableComponent />
                  </div>
                ) : (
                  <DataTableComponent tableHeadData={tableHeadData}>
                    {props.getBeautyExperts?.beautyExperts?.data?.map(
                      (item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.firstName}</td>
                          <td>{item.lastName}</td>
                          <td>{item.profession}</td>
                          <td>{item.phoneNumber}</td>
                          <td>{item.servicePriceRate} %</td>
                         
                          <ModifyButtonsComponent
                          delFunc={() => getIdFunc(item.id)}
                        />
                        </tr>
                      )
                    )}
                  </DataTableComponent>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    getBeautyExperts: state.getBeautyExperts,
    deleteBeautyExpert: state.deleteBeautyExpert,
  };
};
export default connect(mapStateToProps, {
  getBeautyExpertAllAction,
  deleteBeautyExpertAction,
})(BeautyExpert);
