import axios from "axios";
import {
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_START,
  AUTH_LOGIN_SUCCESS,
} from "../actionTypes/authActionTypes";
import { baseUrl } from "../../api/baseApi";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export const authLoginAction = (data) => (dispatch) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });
  dispatch({
    type: AUTH_LOGIN_START,
  });
  axios
    .post(`${baseUrl}/Auth/login`, data)
    .then((response) => {
      dispatch({
        type: AUTH_LOGIN_SUCCESS,
        payload: response.data,
      });
      if (response.data.token !== null) {
        (axios.defaults.headers.common["Authorization"] =
          "Bearer " + response.data.token);
        Toast.fire({
          icon: "success",
          title: "Daxil olundu!"
        });
       setTimeout(() => {
           window.location.replace("/");
       }, 3000);
        localStorage.setItem("token", `Bearer ${response.data.token}`);
        localStorage.setItem("tokenExpiration", response.data.expiration);
      } else {
        delete axios.defaults.headers.common["Authorization"];
        localStorage.removeItem("token");
      }
    })
    .catch((error) => {
      dispatch({
        type: AUTH_LOGIN_ERROR,
        payload: error,
      });
      Toast.fire({
        icon: "error",
        title: "Email və ya şifrə yanlışdır!"
      });
    });
};



