import axios from "axios";
import { baseUrl } from "../../api/baseApi";
import { config } from "../../api/configApi";
import { GET_ALL_PAYMENT_METHOD_ERROR, GET_ALL_PAYMENT_METHOD_START, GET_ALL_PAYMENT_METHOD_SUCCESS } from "../actionTypes/paymentMethodActionTypes";


export const getAllPaymentMethodAction = () => (dispatch) => {
    dispatch({
      type: GET_ALL_PAYMENT_METHOD_START,
    });
    axios
      .get(`${baseUrl}/PaymentMethod/GetAllPaymentMethod`, config)
      .then((response) => {
        dispatch({
          type: GET_ALL_PAYMENT_METHOD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_PAYMENT_METHOD_ERROR,
          payload: error,
        });
      });
  };