import { Navigate, Outlet } from "react-router";



export const ProtectedAdminRoute = ({ isAuthenticated,isAuthenticatedAdmin  }) => {
 if (isAuthenticated === true) {
  if (isAuthenticatedAdmin===false) {
    return  <Navigate to="/Forbidden" replace /> ;
  }
  else
  return  <Outlet/>;
 }
  else
  return <Navigate to="/login" replace /> ;
};

const ProtectedRoute = ({ isAuthenticated }) => {
    if (isAuthenticated===false) {
      return  <Navigate to="/login" replace /> ;
    }
    else
    return <Outlet/> ;
  };
  export default ProtectedRoute;