import "./App.css";

import MainRoute from "./routes/MainRoute";
import { RouterProvider } from "react-router-dom";
import { useEffect } from "react";
import { getToken, getTokenExpiration } from "./constants/role/getToken";

function App() {
  useEffect(() => {
    if (getToken != null && getTokenExpiration != null) {
      var nowDate = new Date();
      var date = new Date(getTokenExpiration);
      if (nowDate.getTime() >= date.getTime()) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        window.location.replace('/login');
      }
    }
  });
  return (
    <>
      <RouterProvider router={MainRoute} />
    </>
  );
}

export default App;
