import { useFormik } from 'formik';
import React from 'react'
import MyInput from '../../components/form/MyInput';
import Form from '../../components/form/Form';
import ErrorText from '../../components/form/ErrorText';
import { connect } from 'react-redux';
import { beautyExpertAddAction } from '../../redux/actions/beautyExpertAction';
import Header from '../../components/Header/Header';
import { addBeautyExpertValidation } from '../../constants/yup/validation';
import { AddTask } from '@mui/icons-material';

const AddBeautyExpert = (b) => {
    const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
        {
          initialValues: {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            profession: "",
            servicePriceRate: 0,
          },
          onSubmit: (values) => {
            const data = {
              FirstName: values.firstName,
              LastName: values.lastName,
              PhoneNumber: values.phoneNumber,
              Profession: values.profession,
              ServicePriceRate: values.servicePriceRate,
            };
            b.beautyExpertAddAction(data);
            
          },
          validationSchema: addBeautyExpertValidation,
        }
      );
  return (
    <div className="beautyExpertAddPage">
    <Header />
    <div className="addForm my-3">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-sm-12 col-md-12 col-lg-5">
            <div className="addCustomerPhoto">
              <img
                className="img-fluid"
                style={{ marginTop: "30px" }}
                src="/images/addExpert.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-7">
            <Form
              submitFunc={handleSubmit}
              titleForm={"mütəxəssis"}
              btnClass="btn btn-success"
              btnTitle="Əlavə et"
              btnIco={<AddTask />}

            >
              <MyInput
                handleChangeFunc={handleChange}
                onBlurFunc={handleBlur}
                id="firstName"
                name="firstName"
                titleName={"ad"}
                placeholder="mütəxəssis adı"
                inputType="text"
              />
              <ErrorText
                error={errors.firstName}
                touched={touched.firstName}
                errorMessage={errors.firstName}
              />
              <MyInput
                handleChangeFunc={handleChange}
                onBlurFunc={handleBlur}
                id="lastName"
                name="lastName"
                titleName={"soyad"}
                placeholder="mütəxəssis soyadı"
                inputType="text"
              />
              <ErrorText
                error={errors.lastName}
                touched={touched.lastName}
                errorMessage={errors.lastName}
              />
              <MyInput
                handleChangeFunc={handleChange}
                onBlurFunc={handleBlur}
                id="phoneNumber"
                name="phoneNumber"
                titleName={"telefon"}
                placeholder="mütəxəssis telefonu"
                inputType="text"
              />
              <ErrorText
                error={errors.phoneNumber}
                touched={touched.phoneNumber}
                errorMessage={errors.phoneNumber}
              />
              <MyInput
                handleChangeFunc={handleChange}
                onBlurFunc={handleBlur}
                id="profession"
                name="profession"
                titleName={"iş alanı"}
                placeholder="vəzifə"
                inputType="text"
              />
              <ErrorText
                error={errors.profession}
                touched={touched.profession}
                errorMessage={errors.profession}
              />
              <MyInput
                handleChangeFunc={handleChange}
                onBlurFunc={handleBlur}
                id="servicePriceRate"
                name="servicePriceRate"
                titleName={"işlədiyi faiz"}
                placeholder="iş faizi"
                inputType="number"
              />
              <ErrorText
                error={errors.servicePriceRate}
                touched={touched.servicePriceRate}
                errorMessage={errors.servicePriceRate}
              />
            
             
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
const mapStateToProps = (state) => {
    return {
      addBeautyExpert: state.beautyExpertAddAction,
    };
  };
export default connect(mapStateToProps, {beautyExpertAddAction})(AddBeautyExpert) 
