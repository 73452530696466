import { ADD_DEPT_ERROR, ADD_DEPT_START, ADD_DEPT_SUCCESS, DELETE_DEPT_ERROR, DELETE_DEPT_START, DELETE_DEPT_SUCCESS, GET_ALL_DEPT_ERROR, GET_ALL_DEPT_START, GET_ALL_DEPT_SUCCESS, GET_DEPT_ERROR, GET_DEPT_START, GET_DEPT_SUCCESS, UPDATE_DEPT_ERROR, UPDATE_DEPT_START, UPDATE_DEPT_SUCCESS } from "../actionTypes/deptActionTypes";

const ADD_INITIAL_DATA = {
    dept: {},
    isLoading: false,
    error: "ERROR THIS API",
  };
  
  export const addDeptReducer = (
    state = ADD_INITIAL_DATA,
    { type, payload }
  ) => {
    switch (type) {
      case ADD_DEPT_START:
        return { ...state, isLoading: true, error: "" };
      case ADD_DEPT_SUCCESS:
        return { ...state, dept: payload, isLoading: false };
      case ADD_DEPT_ERROR:
        return { ...state, error: payload, isLoading: false };
      default:
        return state;
    }
  };
  
  const ALL_INITIAL_DATA = {
    allDept: [],
    isLoading: false,
    error: "ERROR THIS API",
  };
  
  export const getAllDeptReducer = (
    state = ALL_INITIAL_DATA,
    { type, payload }
  ) => {
    switch (type) {
      case GET_ALL_DEPT_START:
        return { ...state, isLoading: true, error: "" };
      case GET_ALL_DEPT_SUCCESS:
        return { ...state, allDept: payload, isLoading: false };
      case GET_ALL_DEPT_ERROR:
        return { ...state, error: payload, isLoading: false };
      default:
        return state;
    }
  };
  
  
  const DELETE_INITIAL_DATA = {
    deptId:0, isLoading:false, error:"ERROR THIS API",
  };
  
  export const deleteDeptReducer = (state= DELETE_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case DELETE_DEPT_START : return {...state, isLoading:true, error:""}
      case DELETE_DEPT_SUCCESS : return {...state, deptId:payload, isLoading:false}
      case DELETE_DEPT_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }
  const GET_INITIAL_DATA = {
    deptId:0, isLoading:false, error:"ERROR THIS API",
  };
  
  export const getDeptReducer = (state= GET_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case GET_DEPT_START : return {...state, isLoading:true, error:""}
      case GET_DEPT_SUCCESS : return {...state, deptId:payload, isLoading:false}
      case GET_DEPT_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }

  const UPDATE_INITIAL_DATA = {
   dept:{}, isLoading:false, error:"ERROR THIS API",
  };
  
  export const updateDeptReducer = (state= UPDATE_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case UPDATE_DEPT_START : return {...state, isLoading:true, error:""}
      case UPDATE_DEPT_SUCCESS : return {...state, dept:payload, isLoading:false}
      case UPDATE_DEPT_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }