import React, { useEffect } from "react";
import DataTableComponent from "../../components/dataTable/DataTableComponent";
import SubHeader from "../../components/Header/SubHeader";
import LoadingComponent from "../../components/loadingBox/LoadingComponent";
import EmptyTableComponent from "../../components/dataTable/EmptyTableComponent";
import ModifyButtonsComponent from "../../components/dataTable/ModifyButtonsComponent";
import { connect } from "react-redux";
import { getAllExpertToServices } from "../../redux/actions/beautyExpertToServiceAction";

const AllExpertToService = (props) => {
  useEffect(() => {
    return props.getAllExpertToServices();
  }, []);
  const dataTableHead = ["No", "Ad və soyad", "Expert faizi", "Xidmət", "Xidmət qiyməti", "Expert qazancı", "Salon qazancı", ""];
  return (
    <div className="AllExpertToService">
      <SubHeader
        backLink="/"
        title="Expertlərin xidmətləri siyahısı"
        btnTitle="expertə xidmət əlavə et"
        btnLink="/addBeautyExpertToService"
      />
      <div className="container-fluid">
        <div className="row">
          {props.getAllBeautyExpertToServices.isLoading ? (
            <LoadingComponent />
          ) : props.getAllBeautyExpertToServices.beautyExpertToServices?.data?.length<=0? (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center my-5">
              <EmptyTableComponent />
            </div>
          ) : (
            <DataTableComponent tableHeadData={dataTableHead}>
              {props.getAllBeautyExpertToServices.beautyExpertToServices?.data?.map(
                (item, index) => (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{item.beautyExpertName}</td>
                    <td>{(item.beautyExpertPriceRate).toFixed(2)} %</td>
                    <td>{item.beautyServiceName}</td>
                    <td>{(item.beautyServicePrice).toFixed(2)} Azn</td>
                    <td>{(item.beautyExpertPriceGain).toFixed(2)} Azn</td>
                    <td>{(item.saloonPriceGain).toFixed(2)} Azn</td>
                    <ModifyButtonsComponent />
                  </tr>
                )
              )}
            </DataTableComponent>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getAllBeautyExpertToServices: state.getAllBeautyExpertToServices,
  };
};

export default connect(mapStateToProps, { getAllExpertToServices })(
  AllExpertToService
);
