import React from "react";
import Header from "../../components/Header/Header";
import Form from "../../components/form/Form";
import { AddTask } from "@mui/icons-material";
import MyInput from "../../components/form/MyInput";
import ErrorText from "../../components/form/ErrorText";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { addBeautyServiceAction } from "../../redux/actions/beautyServiceAction";
import { addBeautyServiceValidation } from "../../constants/yup/validation";

const BeautyServiceAdd = (s) => {
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        serviceName: "",
        serviceDetail: "",
        servicePrice: 0,
      },
      onSubmit: (values) => {
        const data = {
          serviceName: values.serviceName,
          serviceDetail: values.serviceDetail,
          servicePrice: values.servicePrice,
        };
        s.addBeautyServiceAction(data);
      },
      validationSchema: addBeautyServiceValidation,
    }
  );
  return (
    <div className="beautyServiceAdd">
      <Header />
      <div className="addForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/addService.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Yeni xidmət əlavə et"}
                btnClass="btn btn-success"
                btnTitle="Əlavə et"
                btnIco={<AddTask />}
              >
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="serviceName"
                  name="serviceName"
                  titleName={"xidmət"}
                  placeholder="xidmət adı"
                  inputType="text"
                />
                <ErrorText
                  error={errors.serviceName}
                  touched={touched.serviceName}
                  errorMessage={errors.serviceName}
                  name={"serviceName"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="serviceDetail"
                  name="serviceDetail"
                  titleName={"ətraflı"}
                  placeholder="xidmət haqqında"
                  inputType="text"
                />
                <ErrorText
                  error={errors.serviceDetail}
                  touched={touched.serviceDetail}
                  errorMessage={errors.serviceDetail}
                  name={"serviceDetail"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="servicePrice"
                  name="servicePrice"
                  titleName={"qiymət"}
                  placeholder="xidmətin qiyməti"
                  inputType="text"
                />
                <ErrorText
                  error={errors.servicePrice}
                  touched={touched.servicePrice}
                  errorMessage={errors.servicePrice}
                  name={"servicePrice"}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addService: state.addBeautyServiceAction,
  };
};

export default connect(mapStateToProps, { addBeautyServiceAction })(
  BeautyServiceAdd
);
