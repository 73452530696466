import React from 'react'
import { Link } from 'react-router-dom'
import './dashboardElement.scss'

const DashboardItem = (props) => {
  return (
    <div className="itemElement">
                <Link className="textLink" to={props.link}>
                  <div className="elementBox">
                    <div className="elementImgBox">
                      <img src={props.img} alt="" />
                    </div>
                    <div className="elementTextBox">
                      <h1>{props.title}</h1>
                    </div>
                  </div>
                </Link>
              </div>
  )
}

export default DashboardItem
