import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Form from "../../components/form/Form";
import { AddTask, EditNote } from "@mui/icons-material";
import MyInput from "../../components/form/MyInput";
import ErrorText from "../../components/form/ErrorText";
import { useFormik } from "formik";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getItemOutGoingAction,
  updateOutGoingAction,
} from "../../redux/actions/outGoingAction";

const UpdateOutGoing = (s) => {
  const outGoing = useSelector((state) => state.getOutGoing);
  const { outGoingId } = useParams();
  let getId = outGoingId;
  const [id, setId] = useState(0);

  useEffect(() => {
    setId(getId);

    return s.getItemOutGoingAction(id);
  }, [id]);
  
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        id: id,
        outGoingName: "",
        description: null,
        outGoingMoney: null,
        outGoingDate: null,
        outGoingCreatedDate: null,
        isDelete: null,
      },
      onSubmit: (values) => {
        const data = {
          id: id,
          outGoingName:
            values.outGoingName == "" || values.outGoingName == null
              ? outGoing.outGoingId.data?.outGoingName
              : values.outGoingName,
          description:
            values.description == "" || values.description == null
              ? outGoing.outGoingId.data?.description
              : values.description,
          outGoingMoney:
            values.outGoingMoney == "" || values.outGoingMoney == null
              ? outGoing.outGoingId.data?.outGoingMoney
              : Number(values.outGoingMoney),
          outGoingDate:
            values.outGoingDate == "" || values.outGoingDate == null
              ? outGoing.outGoingId.data?.outGoingDate
              : values.outGoingDate,
          outGoingCreatedDate:
            values.outGoingCreatedDate == "" ||
            values.outGoingCreatedDate == null
              ? outGoing.outGoingId.data?.outGoingCreatedDate
              : values.outGoingCreatedDate,
          isDelete:
            values.isDelete == "" || values.isDelete == null
              ? Boolean(outGoing.outGoingId.data?.isDelete)
              : values.isDelete,
        };
        console.log(data);

        s.updateOutGoingAction(data);
      },
    }
  );
  return (
    <div className="updateOutGoing">
      <Header />
      <div className="addForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/addCourse.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Borcda dəyişiklik et"}
                btnClass="btn btn-success"
                btnTitle="Düzəliş et"
                btnIco={<EditNote />}
              >
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="outGoingName"
                  name="outGoingName"
                  titleName={"xərc"}
                  placeholder="xərc adı"
                  inputType="text"
                  defaultVal={outGoing.outGoingId.data?.outGoingName}
                />
                <ErrorText
                  error={errors.outGoingName}
                  touched={touched.outGoingName}
                  errorMessage={errors.outGoingName}
                  name={"outGoingName"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="description"
                  name="description"
                  titleName={"ətraflı"}
                  placeholder="Xərc haqqında"
                  inputType="text"
                  defaultVal={outGoing.outGoingId.data?.description}
                />
                <ErrorText
                  error={errors.description}
                  touched={touched.description}
                  errorMessage={errors.description}
                  name={"description"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="outGoingMoney"
                  name="outGoingMoney"
                  titleName={"qiymət"}
                  placeholder="xərcin qiyməti"
                  inputType="text"
                  defaultVal={outGoing.outGoingId.data?.outGoingMoney}
                />
                <ErrorText
                  error={errors.outGoingMoney}
                  touched={touched.outGoingMoney}
                  errorMessage={errors.outGoingMoney}
                  name={"outGoingMoney"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="outGoingDate"
                  name="outGoingDate"
                  titleName={"xərclənmə tarixi"}
                  inputType="date"
                  defaultVal={outGoing.outGoingId.data?.outGoingDate}
                />
                <ErrorText
                  error={errors.outGoingDate}
                  touched={touched.outGoingDate}
                  errorMessage={errors.outGoingDate}
                  name={"outGoingDate"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="outGoingCreatedDate"
                  name="outGoingCreatedDate"
                  titleName={"xərcin yaranma tarixi"}
                  inputType="date"
                  defaultVal={outGoing.outGoingId.data?.outGoingCreatedDate}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    updateOutgoing: state.updateOutgoing,
    getOutGoing: state.getOutGoing,
  };
};

export default connect(mapStateToProps, {
  updateOutGoingAction,
  getItemOutGoingAction,
})(UpdateOutGoing);
