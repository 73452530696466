import axios from "axios";
import { baseUrl } from "../../api/baseApi";
import { config } from "../../api/configApi";
import { getToken } from "../../constants/role/getToken";
import {
  ADD_DEPT_ERROR,
  ADD_DEPT_START,
  ADD_DEPT_SUCCESS,
  DELETE_DEPT_ERROR,
  DELETE_DEPT_START,
  DELETE_DEPT_SUCCESS,
  GET_ALL_DEPT_ERROR,
  GET_ALL_DEPT_START,
  GET_ALL_DEPT_SUCCESS,
  GET_DEPT_ERROR,
  GET_DEPT_START,
  GET_DEPT_SUCCESS,
  UPDATE_DEPT_ERROR,
  UPDATE_DEPT_START,
  UPDATE_DEPT_SUCCESS,
} from "../actionTypes/deptActionTypes";

export const addDeptAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_DEPT_START,
  });

  axios
    .post(`${baseUrl}/Dept/AddDept`, data, config)
    .then((response) => {
      dispatch({
        type: ADD_DEPT_SUCCESS,
        payload: response.data,
      });
      window.location.replace("/allDept");
    })
    .catch((error) => {
      dispatch({
        type: ADD_DEPT_ERROR,
        payload: error,
      });
    });
};

export const getAllDeptAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_DEPT_START,
  });
  const configGetAll = {
    headers: {
      Authorization: getToken,
    },
    params: {
      monthFilter: data.monthFilter,
    },
  };
  axios
    .get(`${baseUrl}/Dept/GetAllDepts`, configGetAll)
    .then((response) => {
      dispatch({
        type: GET_ALL_DEPT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_DEPT_ERROR,
        payload: error,
      });
    });
};

export const deleteDeptAction = (data) => (dispatch) => {
  const configDelete = {
    params: {
      deptId: data.deptId,
    },
    headers: {
      Authorization: getToken,
    },
  };

  dispatch({
    type: DELETE_DEPT_START,
  });

  axios
    .put(`${baseUrl}/Dept/DeleteDept`, null, configDelete)
    .then((response) => {
      dispatch({
        type: DELETE_DEPT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: DELETE_DEPT_ERROR,
        payload: error,
      });
    });
};

export const getItemDeptAction = (deptId) => (dispatch) => {
 
  dispatch({
    type: GET_DEPT_START,
  });

  axios
    .get(`${baseUrl}/Dept/GetDept/${deptId}`, null, config)
    .then((response) => {
      dispatch({
        type: GET_DEPT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_DEPT_ERROR,
        payload: error,
      });
    });
};


export const updateDeptAction = (data) => (dispatch) => {

  dispatch({
    type: UPDATE_DEPT_START,
  });

  axios
    .post(`${baseUrl}/Dept/UpdateDept`, data, config)
    .then((response) => {
      dispatch({
        type: UPDATE_DEPT_SUCCESS,
        payload: response.data,
      });
      window.location.replace("/allDept");
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_DEPT_ERROR,
        payload: error,
      });
    });
};
