export const GET_BEAUTY_EXPERT_ALL_START = "GET_BEAUTY_EXPERT_ALL_START";
export const GET_BEAUTY_EXPERT_ALL_SUCCESS = "GET_BEAUTY_EXPERT_ALL_SUCCESS";
export const GET_BEAUTY_EXPERT_ALL_ERROR = "GET_BEAUTY_EXPERT_ALL_ERROR";

export const ADD_BEAUTY_EXPERT_START = "ADD_BEAUTY_EXPERT_START";
export const ADD_BEAUTY_EXPERT_SUCCESS = "ADD_BEAUTY_EXPERT_SUCCESS";
export const ADD_BEAUTY_EXPERT_ERROR = "ADD_BEAUTY_EXPERT_ERROR";


export const DELETE_BEAUTY_EXPERT_START = "DELETE_BEAUTY_EXPERT_START";
export const DELETE_BEAUTY_EXPERT_SUCCESS = "DELETE_BEAUTY_EXPERT_SUCCESS";
export const DELETE_BEAUTY_EXPERT_ERROR = "DELETE_BEAUTY_EXPERT_ERROR";

