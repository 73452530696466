export const ADD_COURSE_START = "ADD_COURSE_START";
export const ADD_COURSE_SUCCESS = "ADD_COURSE_SUCCESS";
export const ADD_COURSE_ERROR = "ADD_COURSE_ERROR";

export const GET_ALL_COURSE_START = "GET_ALL_COURSE_START";
export const GET_ALL_COURSE_SUCCESS = "GET_ALL_COURSE_SUCCESS";
export const GET_ALL_COURSE_ERROR = "GET_ALL_COURSE_ERROR";

export const DELETE_COURSE_START = "DELETE_COURSE_START";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_ERROR = "DELETE_COURSE_ERROR";


export const UPDATE_COURSE_START = "UPDATE_COURSE_START";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_ERROR = "UPDATE_COURSE_ERROR";

export const GET_COURSE_START = "GET_COURSE_START";
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
export const GET_COURSE_ERROR = "GET_COURSE_ERROR";