import React from "react";

const ErrorText = (props) => {
  return (
    <>
      <span name={props.name} style={{color:"darkred", fontWeight:"500", display:"block", marginBottom:"5px", marginTop:"-5px"}}>{props.errorMessage}</span>
    </>
  );
};

export default ErrorText;
