export const  GET_CUSTOMERS_START = 'GET_CUSTOMERS_START';
export const  GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const  GET_CUSTOMERS_ERROR = 'GET_CUSTOMERS_ERROR';

export const GET_CUSTOMER_ITEM_START = 'GET_CUSTOMER_ITEM_START';
export const GET_CUSTOMER_ITEM_SUCCESS = 'GET_CUSTOMER_ITEM_SUCCESS';
export const GET_CUSTOMER_ITEM_ERROR = 'GET_CUSTOMER_ITEM_ERROR';


export const POST_CUSTOMER_ADD_START = "POST_CUSTOMER_ADD_START";
export const POST_CUSTOMER_ADD_SUCCESS = "POST_CUSTOMER_ADD_SUCCESS";
export const POST_CUSTOMER_ADD_ERROR = " POST_CUSTOMER_ADD_ERROR";