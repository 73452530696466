import React, { useEffect, useState } from "react";
import SubHeader from "../../components/Header/SubHeader";
import LoadingComponent from "../../components/loadingBox/LoadingComponent";
import EmptyTableComponent from "../../components/dataTable/EmptyTableComponent";
import DataTableComponent from "../../components/dataTable/DataTableComponent";
import ModifyButtonsComponent from "../../components/dataTable/ModifyButtonsComponent";
import { connect } from "react-redux";
import {
  deleteOrderServiceAction,
  getAllServiceOrdersAction,
} from "../../redux/actions/serviceOrderAction";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { deleteFunc } from "../../constants/delete/DeleteForm";

const ServiceOrder = (props) => {
  const dataTableHead = [
    "S-No",
    "Müştəri adı",
    "Mütəxəssis adı",
    "Sifariş əlavə olunma Tarixi",
    "Yaranma Tarixi",
    "",
  ];
  useEffect(() => {
    return props.getAllServiceOrdersAction({monthFilter:""});
  }, []);


 
  const getIdFunc = (id) => {
    const data = {
      serviceOrderId: id
     }
    deleteFunc({ deleteActionName:(props.deleteOrderServiceAction(data)),locationReplaceName:"/allServiceOrder"});
  };
  const { handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      monthFilter: "",
    },

    onSubmit: (values) => {
      const data = {
        monthFilter: values.monthFilter,
      };
      props.getAllServiceOrdersAction(data);
    },
  });
  return (
    <div className="serviceOrder">
      <div className="serviceOrderHead">
        <SubHeader
          backLink="/"
          title="Sifariş siyahısı"
          btnTitle="sifariş əlavə et"
          btnLink="/serviceOrderAdd"
        />
      </div>
      <div className="serviceOrderBody">
        <div className="container">
        <form
            style={{ marginBottom: "30px", marginTop: "20px" }}
            onSubmit={handleSubmit}
          >
            <div className="container">
              <div className="row align-items-center">
              
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                  <input
                    style={{
                      marginTop: "12px",
                      padding: "10px 25px",
                      borderRadius: "8px",
                      outline: "none",
                      border: "0.8px solid #ccc",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="monthFilter"
                    name="monthFilter"
                    type="month"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                  <button
                    className="btn btn-secondary"
                    style={{ marginTop: "15px", padding: "10px 20px" }}
                    type="submit"
                  >
                    Filter et
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="row align-items-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <Link
                to={"/serviceOrderDetailAdd"}
                className="btn btn-warning my-4"
              >
                Sifarişi tamamla
              </Link>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
              {props.serviceOrders.isLoading ? (
                <LoadingComponent />
              ) : props.serviceOrders.serviceOrders?.data == null ? (
                <EmptyTableComponent />
              ) : (
                <DataTableComponent tableHeadData={dataTableHead}>
                  {props.serviceOrders?.serviceOrders?.data?.map(
                    (item, index) => (
                      <tr key={index}>
                        <td>{item.orderId}</td>
                        <td>{item.customerName}</td>
                        <td>{item.beautyExpertName}</td>
                        <td>{item.addOrderDate.substring(0, 10)}</td>
                        <td>{item.orderDate.substring(0, 10)}</td>
                        <ModifyButtonsComponent
                          delFunc={() => getIdFunc(item.orderId)}
                        />
                      </tr>
                    )
                  )}
                </DataTableComponent>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    serviceOrders: state.getAllServiceOrders,
    deleteOrder: state.deleteOrder,
  };
};

export default connect(mapStateToProps, {
  getAllServiceOrdersAction,
  deleteOrderServiceAction,
})(ServiceOrder);
