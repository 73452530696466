import React, { useEffect } from "react";
import DataTableComponent from "../../components/dataTable/DataTableComponent";
import SubHeader from "../../components/Header/SubHeader";
import LoadingComponent from "../../components/loadingBox/LoadingComponent";
import EmptyTableComponent from "../../components/dataTable/EmptyTableComponent";
import ModifyButtonsComponent from "../../components/dataTable/ModifyButtonsComponent";
import { connect } from "react-redux";
import { deleteRentRoomAction, getAllRentRoomAction } from "../../redux/actions/rentRoomAction";
import { useFormik } from "formik";
import { deleteFunc } from "../../constants/delete/DeleteForm";

const RentRoom = (props) => {
  useEffect(() => {
    return props.getAllRentRoomAction({monthFilter:""});
  }, []);
  const dataTableHead = [
    "No",
    "Otaq no",
    "Otaq adı",
    "İcarə qiyməti",
    "İcarə tarixi",
    "İcarə yaranma tarixi",
    "",
  ];
  const dataCounts = props.getAllRentRooms.rentRooms?.data;
  const rentRoomsPriceList = [];
  var totalPrice = 0;
  dataCounts?.map((item) => {
    return rentRoomsPriceList.push(item.rentPrice);
  });
  rentRoomsPriceList.map((item) => {
    return (totalPrice += item);
  });
  const dataTableFoot = {
    title: "Ümumi gəlir",
    content: totalPrice.toFixed(2) + " Azn",
  };
  const { handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      monthFilter: "",
    },

    onSubmit: (values) => {
      const data = {
        monthFilter: values.monthFilter,
      };

      props.getAllRentRoomAction(data);

    },
  });
  const getIdFunc = (id) => {
    const myData = {
      rentRoomId: id
     }
    deleteFunc({ deleteActionName:(props.deleteRentRoomAction(myData)),locationReplaceName:"/allRentRoom"});
  };
  
  return (
    <div className="allRentRoom">
      <SubHeader
        backLink="/"
        title="İcarə siyahısı"
        btnTitle="icarə əlavə et"
        btnLink="/addRentRoom"
      />
      <div className="container-fluid">
      <form
            style={{ marginBottom: "30px", marginTop: "20px" }}
            onSubmit={handleSubmit}
          >
            <div className="container">
              <div className="row align-items-center">
              
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                  <input
                    style={{
                      marginTop: "12px",
                      padding: "10px 25px",
                      borderRadius: "8px",
                      outline: "none",
                      border: "0.8px solid #ccc",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="monthFilter"
                    name="monthFilter"
                    type="month"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                  <button
                    className="btn btn-secondary"
                    style={{ marginTop: "15px", padding: "10px 20px" }}
                    type="submit"
                  >
                    Filter et
                  </button>
                </div>
              </div>
            </div>
          </form>
        <div className="row">
          {props.getAllRentRooms.isLoading ? (
            <>
            <LoadingComponent />
            </>
          ) : props.getAllRentRooms.rentRooms?.data?.length === 0? (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center my-5">
              <EmptyTableComponent />
              {
                console.log(props.getAllRentRooms.rentRooms?.data?.length)
                
              }
            </div>
          ) : (
            <DataTableComponent
              dataTableFoot={dataTableFoot}
              tableHeadData={dataTableHead}
            >
              {props.getAllRentRooms.rentRooms?.data?.map((item, index) => (
                <tr key={index}>
                    <td>{index + 1}</td>
                  <td>{item.roomNo}</td>
                  <td>{item.roomName}</td>
                  <td>{item.rentPrice.toFixed(2)} Azn</td>
                  <td>{item.rentDate}</td>
                  <td>{item.createRentDate.substring(0,10)}</td>

                  <ModifyButtonsComponent
                    delFunc={() => getIdFunc(item.id)}
                    linkUrl={`/UpdateRentRoom/${item.id}`} 
                  />
                </tr>
              ))}
            </DataTableComponent>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getAllRentRooms: state.getAllRentRooms,
    deleteRentRoom: state.deleteRentRoom
  };
};

export default connect(mapStateToProps, { getAllRentRoomAction, deleteRentRoomAction })(RentRoom);
